import { Menu, Transition } from "@headlessui/react"
import { MoreHorizontal, Trash2 } from "lucide-react"
import { Fragment, MouseEvent } from "react"

type Props = {
  className?: string
  deleteAction?: (event: MouseEvent) => void
  actions: any[]
}

const classNameDefault = ""

const RowOptions = ({
  className = classNameDefault,
  actions,
  deleteAction
}: Props) => (
  <div className={className}>
    <Menu as="div" className="relative inline-block text-left z-10">
      <Menu.Button>
        <MoreHorizontal
          className="text-neutral-400"
          size={16}
          aria-hidden="true"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute -right-7 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1 ">
            {actions.map((action, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    onClick={action.onClick}
                    className={`${
                      active ? "bg-primary-400 text-white" : "text-base-dark"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  >
                    <action.icon
                      className={`mr-2 ${
                        active ? "text-white" : "text-neutral-400"
                      }`}
                      size={16}
                      aria-hidden="true"
                    />
                    {action.label}
                  </button>
                )}
              </Menu.Item>
            ))}

            {deleteAction ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={deleteAction}
                    className={`${
                      active ? "border-red-500" : "border-transparent"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm text-red-500 border`}
                  >
                    <Trash2
                      className="mr-2 text-red-500"
                      size={16}
                      aria-hidden="true"
                    />
                    Delete
                  </button>
                )}
              </Menu.Item>
            ) : null}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
)

export default RowOptions
