import { Activity } from "../../types"

export const MAP_TYPE_TO_STRING = {
  pricing_empty: "got empty pricing",
  lead_workflow_loaded: "loaded lead workflow",
  lead_workflow_modal_loaded: "loaded lead workflow modal",
  rate_table_loaded: "loaded rate table"
}

export const convertActivityTypeToString = (activity: Activity): string => {
  if (!activity) return ""
  const valueFromMap = (MAP_TYPE_TO_STRING as any)[activity.type]
  return valueFromMap ?? `made an activity "${activity.type}"`
}
