/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowSmRightIcon, SupportIcon } from "@heroicons/react/outline"

interface Props {
  isFull: boolean
}

const Support: React.FC<Props> = ({ isFull }) => {
  return (
    <div className="p-2 pb-12 text-gray-300 whitespace-nowrap overflow-hidden">
      {isFull ? (
        <>
          <p className="pb-4 text-sm text-gray-400 uppercase">
            Other resources
          </p>
          <a
            href="#"
            className="flex-shrink-0 w-full group block cursor-pointer"
          >
            <div className="flex items-center">
              <p className="text-sm text-white">Help Articles</p>
              <ArrowSmRightIcon className="ml-1 flex-shrink-0 h-6 w-6 text-white" />
            </div>
          </a>
        </>
      ) : (
        <div className="flex items-center justify-center cursor-pointer">
          <SupportIcon className="h-6 w-6 text-white" />
        </div>
      )}
    </div>
  )
}

export default Support
