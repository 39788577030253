import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import bbApi from "../../api"
import { OrgActivity, Organization, OrgMember } from "../../types"
import { RootState } from "../store"
import { setApiError, setMessage } from "./message"

export interface OrgState {
  details?: Organization
  members: OrgMember[]
  membersCount: number
  activities: OrgActivity[]
  isLoading: boolean
  isLoadingMembers: boolean
}

const initialState: OrgState = {
  members: [],
  activities: [],
  membersCount: 0,
  isLoading: false,
  isLoadingMembers: false
}

export const fetchOrgDetails = createAsyncThunk(
  "org/fetchOrgDetails",
  async (_, thunkAPI) => {
    try {
      const data = await bbApi.org.get()

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const fetchOrgMembers = createAsyncThunk(
  "org/fetchOrgMembers",
  async (orgId: string, thunkAPI) => {
    try {
      const data = await bbApi.org.getMembers(orgId)

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const fetchOrgActivities = createAsyncThunk(
  "org/fetchOrgActivities",
  async (
    payload: {
      groupId: string
      rangeStart: number
      rangeEnd: number
      limit?: number
    },
    thunkAPI
  ) => {
    try {
      const { groupId, rangeStart, rangeEnd, limit = 5 } = payload
      const data = await bbApi.activity.get(
        groupId,
        rangeStart,
        rangeEnd,
        limit
      )

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    setOrgDetails: (state, action: PayloadAction<{ data: Organization }>) => {
      state.details = action.payload.data
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchOrgDetails.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchOrgDetails.fulfilled, (state, action) => {
      state.isLoading = false
      state.details = action.payload?.data
    })
    builder.addCase(fetchOrgDetails.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(fetchOrgMembers.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchOrgMembers.fulfilled, (state, action) => {
      state.isLoading = false
      state.membersCount = action.payload?.data?.count ?? 0
      state.members = action.payload?.data?.members ?? []
    })
    builder.addCase(fetchOrgMembers.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(fetchOrgActivities.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchOrgActivities.fulfilled, (state, action) => {
      state.isLoading = false
      state.activities = action.payload?.data ?? []
    })
    builder.addCase(fetchOrgActivities.rejected, (state, action) => {
      state.isLoading = false
    })
  }
})

export const { setOrgDetails } = orgSlice.actions

export const selectOrgDetails = (state: RootState) => state.org.details
export const selectOrgMembers = (state: RootState) => state.org.members
export const selectOrgMembersLoading = (state: RootState) =>
  state.org.isLoadingMembers
export const selectOrgActivities = (state: RootState) => state.org.activities
export const selectOrgBrandsIDs = (state: RootState) => {
  const brands = state.org.details?.brands ?? []
  return brands.map(brand => brand.id).join(",")
}

export default orgSlice.reducer
