export const RateList = [
  {
    id: "0",
    value: 4.875,
    loan_term: "30 yr fixed",
    price: 100,
    fee: 995,
    loan_amount: 780000,
    pi: 4490,
    type: "Loan exchange Agency Fixed [...]",
    investor: "MAXEX"
  },
  {
    id: "1",
    value: 5,
    loan_term: "30 yr fixed",
    price: 100,
    fee: 995,
    loan_amount: 780000,
    pi: 4490,
    type: "Loan exchange Agency Fixed [...]",
    investor: "MAXEX"
  },
  {
    id: "2",
    value: 4.75,
    loan_term: "30 yr fixed",
    price: 100,
    fee: 995,
    loan_amount: 780000,
    pi: 4490,
    type: "Loan exchange Agency Fixed [...]",
    investor: "MAXEX"
  },
  {
    id: "3",
    value: 5.25,
    loan_term: "30 yr fixed",
    price: 100,
    fee: 995,
    loan_amount: 780000,
    pi: 4490,
    type: "Loan exchange Agency Fixed [...]",
    investor: "MAXEX"
  }
]
