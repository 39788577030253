type WrapperProps = {
  children: React.ReactNode
  className?: string
  theme: "active" | "initial" | "idle"
}

type Props = {
  className?: string
}

const classNameDefault = ""

const Wrapper = ({
  children,
  theme,
  className = classNameDefault
}: WrapperProps) => (
  <span
    className={`status status-${theme} ${className} h-5 inline-flex items-center`}
  >
    <i aria-hidden="true" className="mr-1" /> {children}
  </span>
)

const Active = (props: Props) => {
  return (
    <Wrapper {...props} theme="active">
      Active
    </Wrapper>
  )
}

const Idle = (props: Props) => {
  return (
    <Wrapper {...props} theme="idle">
      Idle
    </Wrapper>
  )
}

const Initial = (props: Props) => {
  return (
    <Wrapper {...props} theme="initial">
      Initial
    </Wrapper>
  )
}

const Status = { Active, Initial, Idle }

export default Status
