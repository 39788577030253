import { Brand } from "../../types"

export const mapBrandToDraft = (data: Brand): Brand => {
  const { website, ...rest } = data

  return {
    ...rest,
    website: website.replace(/(^\w+:|^)\/\//, "")
  }
}

export const mapDraftToBrand = (data: Brand): Brand => {
  const { website, ...rest } = data

  return {
    ...rest,
    website: `https://${website}`
  }
}

export const convertBoolToEnabled = (bool: boolean) =>
  bool ? "Enabled" : "Disabled"

export const convertBoolToYesNo = (bool: boolean) => (bool ? "Yes" : "No")
