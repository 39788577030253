import { useEffect } from "react"
import { Plus } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../common/hooks/useStore"
import {
  fetchOrgMembers,
  selectOrgDetails,
  selectOrgMembers,
  selectOrgMembersLoading
} from "../../redux/slices/org"
import { PageHeader, PrimaryBtn } from "../../common/ui"
import { LOsTable } from "../../common/ui/loan-officers"
import { RequireRole } from "../../common/validation"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"

const LOPage = () => {
  const dispatch = useAppDispatch()
  const orgDetails = useAppSelector(selectOrgDetails)
  const isLoading = useAppSelector(selectOrgMembersLoading)
  const orgMembers = useAppSelector(selectOrgMembers)

  useEffect(() => {
    // Fetch organization members
    if (orgDetails?.id) {
      dispatch(fetchOrgMembers(orgDetails.id))
    }
  }, [dispatch, orgDetails?.id])

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["loan-officers"]}>
      <div className="p-6 bg-white">
        <PageHeader title="Loan officers">
          <PrimaryBtn onClick={() => {}}>
            <Plus size={16} className="mr-1" /> New Lo
          </PrimaryBtn>
        </PageHeader>
        <LOsTable isLoading={isLoading} data={orgMembers} />
      </div>
    </RequireRole>
  )
}

export default LOPage
