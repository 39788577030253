import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit"
import orgReducer from "./slices/org"
import authReducer, { STORE_RESET } from "./slices/auth"
import messageReducer from "./slices/message"
import integrationsReducer from "./slices/integrations"
import appovedURLsReducer from "./slices/appoved-urls"
import notificationsReducer from "./slices/notifications"
import brandReducer from "./slices/brand"
import leadReducer from "./slices/lead"
import rateflowReducer from "./slices/rateflow"
import activityReducer from "./slices/activity"

const reducer = combineReducers({
  org: orgReducer,
  auth: authReducer,
  message: messageReducer,
  appovedURLs: appovedURLsReducer,
  integrations: integrationsReducer,
  notifications: notificationsReducer,
  brand: brandReducer,
  lead: leadReducer,
  rateflow: rateflowReducer,
  activity: activityReducer
})

const resettableRootReducer = (
  state: ReturnType<typeof reducer> | undefined,
  action: AnyAction
) => {
  if (action.type === STORE_RESET) {
    return reducer(undefined, action)
  }
  return reducer(state, action)
}

export const store = configureStore({
  reducer: resettableRootReducer,
  devTools: process.env.NODE_ENV !== "production"
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
