import { ColumnDef } from "@tanstack/react-table"
import { FC, useState } from "react"
import { Lead } from "../../../../types"
import Table, { ViewType } from "../../table"
import { ToggleView } from "../../table/toolbar"
import LeadsCell from "./LeadsCell"
import OwnerCell from "./OwnerCell"

interface Props {
  data: Lead[]
}

const BrandDetailsLeads: FC<Props> = ({ data }) => {
  const [viewType, setViewType] = useState<ViewType>("table")

  const columns: ColumnDef<Lead>[] = [
    {
      id: "name",
      header: "Leads",
      accessorFn: row => row,
      footer: props => props.column.id,
      cell: props => <LeadsCell data={props.getValue() as any} />
    },
    {
      id: "owner",
      header: "Owner",
      accessorFn: row => row,
      footer: props => props.column.id,
      cell: props => <OwnerCell data={props.getValue() as any} />
    }
  ]
  return (
    <div className="px-8">
      <Table
        data={data}
        columns={columns}
        viewType={viewType}
        setViewType={setViewType}
        pageSize={12}
        searchPlaceholder="Search leads by name or email"
        renderCard={row => <></>}
        searchBarVisible={false}
        extraHeaderElement={
          <ToggleView
            className="ml-auto"
            viewType={viewType}
            setViewType={setViewType}
          />
        }
      />
    </div>
  )
}

export default BrandDetailsLeads
