import { Rate } from "../../../types"

interface Props {
  data: Rate
}

const RateCard: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex-1 border border-gray-300 rounded-md p-4">
      <div className="font-bold text-lg">{data.value}</div>
      <div className="text-xs uppercase">{data.loan_term}</div>

      <div className="flex pt-6">
        <div className="flex-1 text-gray-300">Price</div>
        <div className="flex-1">{data.price}</div>
      </div>

      <div className="flex pt-6">
        <div className="flex-1 text-gray-300">Fee</div>
        <div className="flex-1">${data.fee}</div>
      </div>

      <div className="flex pt-6">
        <div className="flex-1 text-gray-300">Loan amount</div>
        <div className="flex-1">{data.loan_amount}</div>
      </div>

      <div className="flex pt-6">
        <div className="flex-1 text-gray-300">P/I</div>
        <div className="flex-1">{data.pi}</div>
      </div>

      <div className="flex pt-6">
        <div className="flex-1 text-gray-300">Type</div>
        <div className="flex-1">{data.type}</div>
      </div>

      <div className="flex pt-6">
        <div className="flex-1 text-gray-300">Investor</div>
        <div className="flex-1">{data.investor}</div>
      </div>
    </div>
  )
}

export default RateCard
