import logo from "../../../assets/logo.svg"
import logoFull from "../../../assets/logo-full.svg"
import { Link } from "react-router-dom"

interface Props {
  isFull?: boolean
  className?: string
}

const isFullDefault = true
const classNameDefault = ""

const Logo = ({
  isFull = isFullDefault,
  className = classNameDefault
}: Props) => (
  <Link to="/">
    <img
      src={isFull ? logoFull : logo}
      className={className}
      alt="BankingBridge logo"
    />
  </Link>
)

export default Logo
