type WrapperProps = {
  children: React.ReactNode
  className?: string
  theme: "danger" | "success" | "warning" | "info"
}

type Props = {
  className?: string
  children: React.ReactNode
}

const classNameDefault = ""

const Wrapper = ({
  children,
  theme,
  className = classNameDefault
}: WrapperProps) => (
  <span className={`badge badge-${theme} ${className}`}>{children}</span>
)

const Danger = (props: Props) => {
  return <Wrapper {...props} theme="danger" />
}

const Info = (props: Props) => {
  return <Wrapper {...props} theme="info" />
}

const Warning = (props: Props) => {
  return <Wrapper {...props} theme="warning" />
}

const Success = (props: Props) => {
  return <Wrapper {...props} theme="success" />
}

const Badge = { Danger, Info, Warning, Success }

export default Badge
