import { Button } from "../buttons"

interface ButtonProps {
  key?: string | number
  label: string
  onClick: () => void
  disabled?: boolean
}

interface Props {
  className?: string
  buttons: ButtonProps[]
  active?: string | number
}

const ButtonGroup: React.FC<Props> = ({ className, buttons, active }) => {
  return (
    <div className={`overflow-hidden ${className ?? ""}`}>
      {buttons.map(({ key, label, onClick, disabled }) => (
        <Button
          key={key}
          className={`rounded-none first:rounded-l-md last:rounded-r-md border border-r-0 last:border-r font-normal overflow-hidden outline-none ${
            active === key
              ? "bg-primary-50 text-primary-600 border-primary-500"
              : "border-gray-300 text-gray-500 hover:bg-gray-50"
          }`}
          disabled={disabled}
          onClick={onClick}
        >
          {label}
        </Button>
      ))}
    </div>
  )
}

export default ButtonGroup
