import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { RequireRole } from "../../common/validation"

type Props = {
  title?: string
}

const LoanProgramsPage: React.FC<Props> = ({ title }) => {
  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["loan-programs"]}>
      <div>
        <div>Loan programs page</div>
      </div>
    </RequireRole>
  )
}

export default LoanProgramsPage
