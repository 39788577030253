import { FC } from "react"
import { Integration } from "../../../../types"
import Card from "../../integrations/table/card"
import columns from "../../integrations/table/columns"
import TableUI from "../../other-table"
import PreviewModal from "../../../../common/ui/preview-modal"
import { useAppDispatch } from "../../../hooks/useStore"
import { setPreviewModal } from "../../../../redux/slices/integrations"

interface Props {
  data: Integration[]
}

const BrandDetailsIntegrations: FC<Props> = ({ data }) => {
  const dispatch = useAppDispatch()

  return (
    <div>
      <TableUI
        CardComponent={Card}
        noHeader
        noToolbar
        columns={columns}
        data={data}
      />
      <PreviewModal
        onClose={() => {
          dispatch(
            setPreviewModal({
              isOpen: false,
              appKey: ""
            })
          )
        }}
      />
    </div>
  )
}

export default BrandDetailsIntegrations
