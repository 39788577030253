import { Lock } from "lucide-react"
import { PrimaryBtn } from "../../common/ui"

const Password = () => {
  return (
    <>
      <form className="space-y-6" action="#" method="POST">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        <div>
          <PrimaryBtn className="w-full group" onClick={() => {}}>
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <Lock
                className="h-5 w-5 text-primary-500 group-hover:text-primary-400"
                aria-hidden="true"
              />
            </span>
            Reset Password
          </PrimaryBtn>
        </div>
      </form>

      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">Or</span>
          </div>
        </div>

        <div className="mt-6 text-center">
          <a
            href="/user/login"
            className="font-medium text-primary-400 hover:text-primary-500"
          >
            Log in
          </a>
        </div>
      </div>
    </>
  )
}

export default Password
