import { Loader2 } from "lucide-react"

type Props = {
  condition: boolean
  elseCondition?: boolean
  isLoading?: boolean
  then: React.ReactNode
  else?: any
  default?: any
}

const If = (props: Props) => {
  let component = null

  if (props.isLoading) {
    component = (
      <div className="flex items-center justify-center">
        <Loader2
          className="animate-spin h-12 w-12 text-primary-alt-400"
          aria-hidden="true"
        />
      </div>
    )
  } else if (props.condition) {
    component = props.then
  } else if (
    (typeof props.elseCondition === "undefined" || props.elseCondition) &&
    props.else
  ) {
    component = props.else
  } else if (props.default) {
    component = props.default
  }

  return component
}

export default If

/**
 * import { h } from "preact"

const If = props => {
  let component = null
  if (props.condition) {
    component = props.then
  } else if (
    (typeof props.elseCondition === "undefined" || props.elseCondition) &&
    props.else
  ) {
    component = props.else
  } else if (props.default) {
    component = props.default
  }

  return component
}

export default If

 */
