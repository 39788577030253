import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { APIError } from "../../types"

const initialState = {}
const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<APIError>) => {
      const message = action.payload.message ?? "Something went wrong"
      return { message }
    },
    setMessage: (state, action) => {
      return { message: action.payload }
    },
    clearMessage: () => {
      return { message: "" }
    }
  }
})

const { reducer, actions } = messageSlice

export const { setApiError, setMessage, clearMessage } = actions

export default reducer
