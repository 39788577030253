import { useEffect, useState, Fragment, useCallback } from "react"
import {
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender
} from "@tanstack/react-table"

import Pagination from "../pagination"
import { Sorter, DropdownFilter, SearchBar, ToggleView, Tabs } from "./toolbar"
import { OrgMember } from "../../../types"
import If from "../if"

type Data = any | OrgMember[]

type Props = {
  CardComponent: ({ row }: any) => JSX.Element
  data: Data
  columns: ColumnDef<any>[]
  columnVisibility?: any
  noToolbar?: boolean
  noHeader?: boolean
  filterKey?: string
  searchPlaceholder?: string
  tabsData?: {
    tabs: { label: string; ids: string[] }[]
    onChangeTab: (newTab: string[]) => void
    currentTab: string[]
  }
}

const TableUI = ({
  data,
  CardComponent,
  columns,
  filterKey,
  noToolbar,
  noHeader,
  tabsData,
  searchPlaceholder,
  columnVisibility = {}
}: Props) => {
  // TABLE
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState("")
  const [viewType, setViewType] = useState<"table" | "grid">("table")

  const instance = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      columnFilters,
      globalFilter
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  })

  const id = instance.getState().columnFilters[0]?.id

  useEffect(() => {
    if (id === "name") {
      if (instance.getState().sorting[0]?.id !== "name") {
        instance.setSorting([{ id: "name", desc: false }])
      }
    }
  }, [id, instance])

  useEffect(() => {
    instance.setPageSize(viewType === "table" ? 10 : 12)
  }, [viewType, instance])

  const onSearch = useCallback((value: string | number) => {
    setGlobalFilter(String(value))
  }, [])

  return (
    <div className="flex flex-col">
      <div className="-mx-6 sm:-mx-6 lg:-mx-6 -mb-6">
        <div
          className={`inline-block min-w-full pb-2 align-middle px-6 lg:px-6 border-white ${
            viewType === "grid" ? "bg-slate-100" : ""
          }`}
        >
          <If
            condition={!noToolbar}
            then={
              <SearchBar
                globalFilter={globalFilter}
                onSearch={onSearch}
                searchPlaceholder={searchPlaceholder}
              >
                <DropdownFilter
                  filterKey={filterKey ?? ""}
                  instance={instance}
                  className="lg:ml-3"
                />
                <Sorter
                  headerGroups={instance.getHeaderGroups()}
                  className="ml-3"
                />
                <ToggleView
                  className="ml-auto"
                  viewType={viewType}
                  setViewType={setViewType}
                />
              </SearchBar>
            }
          />
          <If
            condition={!!tabsData}
            then={
              <Tabs instance={instance} data={tabsData}>
                <ToggleView
                  className="ml-auto"
                  viewType={viewType}
                  setViewType={setViewType}
                />
              </Tabs>
            }
          />

          <div className="table-wrapper">
            {viewType === "table" ? (
              <table className="min-w-full">
                <thead className={noHeader ? "sr-only" : ""}>
                  {instance.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id} className="text-neutral-400">
                      {headerGroup.headers.map((header, i) => {
                        return (
                          <Fragment key={i}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </Fragment>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {instance.getRowModel().rows.map(row => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          return (
                            <Fragment key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Fragment>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            ) : (
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pt-4">
                {instance.getRowModel().rows.map(row => (
                  <li
                    key={row.id}
                    className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
                  >
                    <CardComponent row={row} />
                  </li>
                ))}
              </ul>
            )}
            {instance.getPageCount() > 1 ? (
              <Pagination
                viewType={viewType}
                instance={instance}
                totalCount={instance.getPageCount()}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableUI
