import { NavLink } from "react-router-dom"
import { classNames } from "../../utils"
import { NavItemType } from "../../../types/NavItem"

type NavbarLinkProps = {
  isFull?: boolean
  isMobile?: boolean
  items: NavItemType[]
}

const NavbarLinks = ({ isFull, isMobile, items }: NavbarLinkProps) => {
  const setClassList = (isActive: boolean) =>
    isMobile
      ? classNames(
          isActive
            ? "bg-indigo-800 text-white"
            : "text-indigo-100 hover:bg-indigo-600",
          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
        )
      : classNames(
          isActive
            ? "bg-gray-900 text-white"
            : "text-gray-300 hover:bg-gray-700 hover:text-white",
          isFull ? "justify-start" : "justify-center",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer whitespace-nowrap overflow-hidden"
        )

  const setIconClassList = (isActive: boolean) =>
    isMobile
      ? classNames(
          isActive ? "text-white" : "text-indigo-100",
          "mr-4 flex-shrink-0"
        )
      : classNames(
          isActive
            ? "text-gray-300"
            : "text-gray-400 group-hover:text-gray-300",
          isFull ? "mr-3" : "mr-0",
          "flex-shrink-0"
        )

  return (
    <>
      {items.map(item => (
        <NavLink
          key={item.name}
          to={item.href}
          className={({ isActive }) => {
            return setClassList(isActive)
          }}
        >
          {({ isActive }) => (
            <>
              <item.icon
                size={20}
                aria-hidden="true"
                className={setIconClassList(isActive)}
              />
              {isFull ? item.name : ""}
            </>
          )}
        </NavLink>
      ))}
    </>
  )
}
export default NavbarLinks
