import { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useAppDispatch, useAppSelector } from "../../hooks/useStore"
import { UserDetails, ApprovedUrls } from "../../../types"
import { selectUserDetails } from "../../../redux/slices/auth"
import If from "../if"
import InputURL from "./input-url"
import ListItem from "./list-item"
import {
  fetchApprovedURLs,
  selectIsLoadingAppovedURLs,
  selectAppovedURLs,
  createApprovedURL,
  selectIsSavingURL
} from "../../../redux/slices/appoved-urls"
import { X } from "lucide-react"

type Props = {
  show: boolean
  toogleModal: (show: boolean) => void
}

const ApprovedUrlsModal = ({ show, toogleModal }: Props) => {
  const dispatch = useAppDispatch()

  const [newURL, setNewURL] = useState("")

  const user: UserDetails | any = useAppSelector(selectUserDetails)
  const isSavingURL: boolean | any = useAppSelector(selectIsSavingURL)

  const isLoading: boolean = useAppSelector(selectIsLoadingAppovedURLs)
  const appovedURLs: ApprovedUrls = useAppSelector(selectAppovedURLs)

  useEffect(() => {
    if (user?.org?.id && show) {
      dispatch(fetchApprovedURLs(user?.org?.id))
    }
  }, [dispatch, user?.org?.id, show])

  useEffect(() => {
    if (!isSavingURL) {
      setNewURL("")
    }
  }, [isSavingURL])

  const onClose = () => {
    setNewURL("")
    toogleModal(false)
  }

  const onSubmit = () => {
    dispatch(
      createApprovedURL({ org_id: user?.org?.id, domain_pattern: newURL })
    )
  }

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 font-bold text-base-dark mb-8 flex items-center"
                >
                  Approved URL's
                  <button
                    type="button"
                    className="hidden sm:block ml-auto bg-white rounded-md primary-alt-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <X size="24" aria-hidden="true" />
                  </button>
                </Dialog.Title>
                <div>
                  <label htmlFor="new-url" className="sr-only">
                    Add new URL
                  </label>
                  <div className="mt-1">
                    <InputURL
                      id="new-url"
                      url={newURL}
                      onChange={setNewURL}
                      onSubmit={onSubmit}
                      isLoading={isSavingURL}
                    />
                  </div>
                </div>
                <div
                  className={`border-t border-zinc-200 mt-3 ${
                    isLoading ? "pt-3" : ""
                  }`}
                >
                  <If
                    isLoading={isLoading}
                    condition={appovedURLs.length > 0}
                    then={
                      <ul className="max-h-96 overflow-auto">
                        {appovedURLs.map((url, index) => (
                          <ListItem key={index} url={url} />
                        ))}
                      </ul>
                    }
                    else={
                      <p className="text-neutral-400 italic text-sm">
                        There is no approved URLs.
                      </p>
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default ApprovedUrlsModal
