import { useEffect, useState, useCallback, Fragment, FC } from "react"
import {
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  flexRender,
  ColumnDef,
  Row
} from "@tanstack/react-table"
import Pagination from "../pagination"
import { Sorter, DropdownFilter, SearchBar, ToggleView } from "./toolbar"
import { classNames } from "../../utils"

export type ViewType = "table" | "grid"

type Props<T> = {
  data: T[]
  viewType: ViewType
  setViewType: (val: ViewType) => void
  canChangeType?: boolean
  columns: ColumnDef<T>[]
  dropdownFilterColumnId?: string
  searchBarVisible?: boolean
  searchPlaceholder: string
  pageSize?: number
  gridsPerPage?: number
  renderCard: (row: Row<T>) => JSX.Element
  extraHeaderElement?: React.ReactNode
}

const Table = <P extends unknown>({
  data,
  viewType,
  setViewType,
  columns,
  searchPlaceholder,
  dropdownFilterColumnId,
  searchBarVisible = true,
  pageSize = 10,
  gridsPerPage = 12,
  renderCard,
  extraHeaderElement = null
}: Props<P>) => {
  // TABLE
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState("")

  const instance = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false
  })

  const id = instance.getState().columnFilters[0]?.id

  useEffect(() => {
    if (id === "name") {
      if (instance.getState().sorting[0]?.id !== "name") {
        instance.setSorting([{ id: "name", desc: false }])
      }
    }
  }, [id, instance])

  useEffect(() => {
    instance.setPageSize(viewType === "table" ? pageSize : gridsPerPage)
  }, [viewType, instance, pageSize, gridsPerPage])

  const onSearch = useCallback((value: string | number) => {
    setGlobalFilter(String(value))
  }, [])

  const { rows } = instance.getRowModel()

  return (
    <div className="flex flex-col">
      <div className="-mx-6 sm:-mx-6 lg:-mx-6 -mb-6">
        <div
          className={`inline-block min-w-full pb-2 align-middle px-6 lg:px-6 border-white ${
            viewType === "grid" ? "bg-slate-100" : ""
          }`}
        >
          {searchBarVisible && (
            <SearchBar
              className="pb-4 px-6 -mx-6"
              globalFilter={globalFilter}
              onSearch={onSearch}
              searchPlaceholder={searchPlaceholder}
            >
              {dropdownFilterColumnId && (
                <DropdownFilter
                  columnID={dropdownFilterColumnId}
                  instance={instance}
                  className="lg:ml-3"
                />
              )}
              <Sorter
                headerGroups={instance.getHeaderGroups()}
                className="ml-3"
              />
              {extraHeaderElement}
            </SearchBar>
          )}
          <div className="h-2" />
          {viewType === "table" ? (
            <table className="min-w-full">
              <thead>
                {instance.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id} className="text-neutral-400">
                    {headerGroup.headers.map((header, i) => (
                      <th
                        scope="col"
                        key={header.id}
                        colSpan={header.colSpan}
                        className={classNames(
                          i === 0 ? "py-4 pl-2 pr-3" : "py-4 px-3",
                          header.column.columnDef?.meta?.headerClassName ?? "",
                          "text-left text-sm font-medium uppercase"
                        )}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {rows.length ? (
                <tbody className="divide-y divide-gray-200">
                  {rows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell, index) => {
                        return (
                          <Fragment key={index}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Fragment>
                        )
                      })}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <div className="px-2 text-neutral-500">No rows found</div>
              )}
            </table>
          ) : rows.length ? (
            <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-4">
              {rows.map(row => (
                <li
                  key={row.id}
                  className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
                >
                  {renderCard(row)}
                </li>
              ))}
            </ul>
          ) : (
            <div className="px-2 text-neutral-500">No rows found</div>
          )}
          {instance.getPageCount() > 1 ? (
            <Pagination
              instance={instance}
              totalCount={instance.getPageCount()}
              viewType={viewType}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Table
