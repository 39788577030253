/**
  API activity wrapper

	GET /api/v1/activity
	query params:
	group_id
	range_start (unix timestamp)
	range_end (unix timestamp)
	limit
**/

import api from "../common/utils/axiosApi"
import { Activity, APIError, OrgActivity } from "../types"

const ENDPOINT = "/api/v1/activity"

const activity = {
  get: async (
    groupId: string,
    rangeStart: number,
    rangeEnd: number,
    limit: number
  ): Promise<OrgActivity[] | APIError> => {
    const url = `${ENDPOINT}?group_id=${groupId}&range_start=${rangeStart}&range_end=${rangeEnd}&limit=${limit}`
    const { data } = await api.get(url)
    return data || []
  },
  getByBrand: async (
    brandId: string,
    days: number = 360
  ): Promise<Activity[] | APIError> => {
    const url = `${ENDPOINT}?brand_id=${brandId}&type=brand&days=${days}`
    const { data } = await api.get(url)
    return data || []
  },
  getPairActivity: async (
    pid: string,
    rangeStart: number,
    rangeEnd: number,
    limit: number
  ): Promise<Activity[] | APIError> => {
    const url = `${ENDPOINT}?type=pair&pair_id=${pid}&limit=${limit}`
    const { data } = await api.get(url)
    return data
  }
}

export default activity
