import { useLocation, Navigate } from "react-router-dom"
import { useAppSelector } from "../hooks/useStore"

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn }: any = useAppSelector(state => {
    return state?.auth ?? false
  })
  const location = useLocation()

  if (!isLoggedIn) {
    // Redirect them to the /user/login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/user/login" state={{ from: location }} replace />
  }

  return children
}

export default RequireAuth
