import { Fragment, useState } from "react"
import { Transition } from "@headlessui/react"
import { X } from "lucide-react"
import { ToastType } from "../../../types"
import { deleteToast } from "../../../redux/slices/notifications"
import ToastIcon from "./toast-icon"
import If from "../if"

const TITLE_DFT = ""
const MSG_DFT = ""

type Props = ToastType & {
  index: number
}

const Toast = ({
  title = TITLE_DFT,
  msg = MSG_DFT,
  theme,
  index,
  timeout = 2000
}: Props) => {
  const [show, setShow] = useState(true)

  const closeToast = () => {
    setShow(false)
    setTimeout(() => {
      deleteToast(index)
    }, 200)
  }

  // if timeout is not 0, then close the toast after timeout
  if (timeout > 0) {
    setTimeout(() => {
      closeToast()
    }, timeout)
  }

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="p-4">
          <div className="flex items-start">
            <If
              condition={theme !== undefined}
              then={<ToastIcon theme={theme} />}
            />

            <div className="ml-3 w-0 flex-1 pt-0.5">
              {title.length > 0 && (
                <p className="text-sm font-medium text-gray-900">{title}</p>
              )}
              {msg.length > 0 && (
                <p className="mt-1 text-sm text-gray-500">{msg}</p>
              )}
            </div>
            <div className="ml-4 flex-shrink-0 flex">
              <button
                type="button"
                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={closeToast}
              >
                <span className="sr-only">Close</span>
                <X size={16} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Toast
