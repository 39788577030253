import { FC, useEffect, useMemo } from "react"
import {
  fetchActivitiesByBrand,
  selectActivities
} from "../../../../redux/slices/activity"
import { Brand, OrgMember } from "../../../../types"
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore"
import BrandDetailsActivityCard from "./Card"

interface Props {
  brand: Brand
  members: OrgMember[]
}

const BrandActivities: FC<Props> = ({ brand, members }) => {
  const dispatch = useAppDispatch()
  const activitiesAll = useAppSelector(selectActivities)

  const activities = useMemo(
    () => activitiesAll.filter(({ brand_id }) => brand_id === brand.id),
    [activitiesAll, brand.id]
  )
  useEffect(() => {
    if (brand?.id) {
      dispatch(
        fetchActivitiesByBrand({
          brand_id: brand.id
        })
      )
    }
  }, [brand.id, dispatch])

  return (
    <div className="px-6">
      {activities.map(activity => (
        <BrandDetailsActivityCard activity={activity} members={members} />
      ))}
    </div>
  )
}

export default BrandActivities
