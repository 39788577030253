import { DateTime } from "luxon"
import { FC, useMemo } from "react"
import { Activity, OrgMember } from "../../../../types"
import { convertActivityTypeToString } from "../../../utils/activity"

interface Props {
  activity: Activity
  members: OrgMember[]
}

const BrandDetailsActivityCard: FC<Props> = ({ activity, members }) => {
  const member = useMemo(
    () => members.find(({ uid }) => uid === activity.loid),
    [activity.loid, members]
  )

  if (!member) return null

  return (
    <div className="flex items-center justify-between text-neutral-400 py-6 border-b border-neutral-200">
      <div className="flex items-center flex-1">
        <div className="w-10 h-10 flex items-center rounded-full overflow-hidden pr-4">
          <img src={member.logo} alt={member.name} />
        </div>
        <div className="pr-2 text-gray-900 font-bold text-lg">
          {member.name}
        </div>
        <div>{convertActivityTypeToString(activity)}</div>
      </div>
      <div>
        {DateTime.fromMillis(parseInt(activity.timestamp)).toFormat(
          "LLL dd 'at' hh:mm"
        )}
      </div>
    </div>
  )
}

export default BrandDetailsActivityCard
