import { useMemo } from "react"
import { Link } from "react-router-dom"
import DashboardLOCard from "../../common/ui/dashboard-lo-card"
import { OrgMember } from "../../types"

interface Props {
  loList: OrgMember[]
}

const RecentLOs: React.FC<Props> = ({ loList }) => {
  const recentLos = useMemo(
    () =>
      loList
        .sort((a, b) => parseInt(a.nid, 10) - parseInt(b.nid, 5))
        .slice(0, 5),
    [loList]
  )
  return (
    <div className="bg-white flex-1 p-6 rounded-md">
      <div className="text-base font-semibold">Recently added LO's</div>
      <div className="py-6">
        {recentLos.map(lo => (
          <DashboardLOCard key={lo.uid} data={lo} />
        ))}
      </div>
      <div className="pt-6 text-blue-300 text-sm border-t border-gray-300 text-right">
        <Link to="/loan-officers">View all LO's</Link>
      </div>
    </div>
  )
}

export default RecentLOs
