import RowOptions from "../../../table/row-options"
import { Pencil } from "lucide-react"
import { Integration } from "../../../../../types"
import { setPreviewModal } from "../../../../../redux/slices/integrations"
import { useAppDispatch } from "../../../../hooks/useStore"

type Props = {
  row: any
}

const Card = ({ row }: Props) => {
  const dispatch = useAppDispatch()
  const { title, type, app_key }: Integration = row.original
  return (
    <div className="text-left p-4 relative">
      <strong className="text-base block">{title}</strong>
      <span className="text-sm text-neutral-400">{type}</span>

      <div className="-ml-4 -mr-4 -mb-4 mt-8 border-t-gray-200 border-t flex">
        <button
          type="button"
          className="text-base p-4 flex-1 border-r-gray-200 border-r text-primary-alt-400"
          onClick={() => {
            dispatch(
              setPreviewModal({
                isOpen: true,
                app_key: app_key
              })
            )
          }}
        >
          Preview
        </button>
        <button
          type="button"
          className="text-base p-4 flex-1 -ml-1 text-primary-alt-400"
        >
          View details
        </button>
      </div>

      <RowOptions
        className="absolute top-4 right-4"
        actions={[{ label: "Edit", icon: Pencil, onClick: () => {} }]}
        deleteAction={() => {}}
      />
    </div>
  )
}

export default Card
