type Props = {
  title?: string
}

const NotFoundPage: React.FC<Props> = ({ title }) => {
  return (
    <div>
      <div>404</div>
    </div>
  )
}

export default NotFoundPage
