import RowOptions from "../../../table/row-options"
import { Inspect, Pencil } from "lucide-react"

interface Props {
  rowId?: string
  onMasquerade: (loid: string) => void
  onEdit: (loid: string) => void
  onDelete: (loid: string) => void
}

const MenuCell: React.FC<Props> = ({
  rowId = "",
  onDelete,
  onEdit,
  onMasquerade
}) => (
  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
    <RowOptions
      actions={[
        {
          label: "Masquerade",
          icon: Inspect,
          onClick: () => onMasquerade(rowId)
        },
        { label: "Edit", icon: Pencil, onClick: () => onEdit(rowId) }
      ]}
      deleteAction={() => onDelete(rowId)}
    />
  </td>
)

export default MenuCell
