import { DateTime } from "luxon"
import { OrgActivity } from "../../../types"
import { formatUnderscoresInField } from "../../utils"
import RateCard from "../rate-card"
import { RateList } from "./mock"

interface Props {
  data?: OrgActivity
}

export const ActivityDetailsFull: React.FC<Props> = ({ data }) => {
  if (!data) return null

  return (
    <div className="text-sm">
      {data.details.lead && (
        <div className="py-6">
          <div className="font-bold text-lg">Lead details</div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Name</div>
              <div className="pt-1">
                {data.details.lead?.first_name} {data.details.lead?.last_name}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">Email</div>
              <div className="pt-1">{data.details.lead?.email}</div>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Phone</div>
              <div className="pt-1">{data.details.lead?.phone}</div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">Date & Time</div>
              <div className="pt-1">
                {DateTime.fromMillis(
                  parseInt(data.details.lead?.timestamp, 10) * 1000
                ).toFormat("LL/dd/yyyy 'at' HH:mm:ss")}
              </div>
            </div>
          </div>
        </div>
      )}

      {data.details.lead?.details && (
        <div className="py-6">
          <div className="font-bold text-lg">Loan information</div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Down payment</div>
              <div className="pt-1">
                {data.details.lead?.details.down_payment}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">List price</div>
              <div className="pt-1">
                {data.details.lead?.details.list_price}
              </div>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Purchase readiness</div>
              <div className="pt-1">
                {formatUnderscoresInField(
                  data.details.lead?.details.purchase_readiness
                )}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">Credit score</div>
              <div className="pt-1">
                {data.details.lead?.details.credit_score}
              </div>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Loan purpose</div>
              <div className="pt-1">
                {formatUnderscoresInField(
                  data.details.lead?.details.loan_purpose
                )}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">Residency type</div>
              <div className="pt-1">
                {formatUnderscoresInField(
                  data.details.lead?.details.residency_type
                )}
              </div>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Military eligible</div>
              <div className="pt-1">
                {data.details.lead?.details.military_eligible}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">Household income</div>
              <div className="pt-1">
                {data.details.lead?.details.household_income}
              </div>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Location</div>
              <div className="pt-1">
                {data.details.lead?.details.location.city}&nbsp;
                {data.details.lead?.details.location.state}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">Property type</div>
              <div className="pt-1">
                {formatUnderscoresInField(
                  data.details.lead?.details.property_type
                )}
              </div>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Loan amount</div>
              <div className="pt-1">
                {data.details.lead?.details.loan_amount}
              </div>
            </div>
            <div className="flex-1">
              <div className="text-gray-300">Quote ID</div>
              <div className="pt-1">{data.details.lead?.details.quote_id}</div>
            </div>
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <div className="text-gray-300">Parent URL</div>
              <div className="pt-1">
                {data.details.lead?.details.parent_url}
              </div>
            </div>
            <div className="flex-1"></div>
          </div>
        </div>
      )}

      <div className="py-6 grid grid-cols-3 gap-6">
        {RateList.map(rate => (
          <div className="">
            <RateCard data={rate} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default ActivityDetailsFull
