import { ColumnDef } from "@tanstack/react-table"
import { FC, useEffect, useMemo, useState } from "react"
import { ChevronRight } from "lucide-react"
import { PricingLog } from "../../../../types"
import Table from "../../table"
import TimestampCell from "./cells/TimestampCell"
import StandardCell from "./cells/StandardCell"
import { useNavigate } from "react-router-dom"
import ButtonGroup from "../../button-group"
import {
  fetchRateflowLogs,
  selectRateflowLogs
} from "../../../../redux/slices/rateflow"
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore"

interface Props {
  brandId: string
}

const BrandDetailsPricingLogs: FC<Props> = ({ brandId }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [period, setPeriod] = useState<number>(1)

  const pricingLogsAll = useAppSelector(selectRateflowLogs)
  const pricingLogs = useMemo(
    () => pricingLogsAll.filter(pl => pl.brand_id === brandId),
    [brandId, pricingLogsAll]
  )

  const minCreated = useMemo(() => {
    const now = new Date().getTime()
    return Math.floor((now - period * 24 * 3600 * 1000) / 1000)
  }, [period])

  const columns: ColumnDef<PricingLog>[] = [
    {
      id: "timestamp",
      header: "Timestamp",
      accessorFn: row => row.timestamp,
      footer: props => props.column.id,
      cell: props => <TimestampCell timestamp={props.getValue() as string} />
    },
    {
      id: "ob_profile_name",
      header: "Pricing Profile",
      accessorFn: row => row.ob_profile_name,
      footer: props => props.column.id,
      cell: props => <StandardCell value={props.getValue() as string} />
    },
    {
      id: "lo_name",
      header: "Loan Officer",
      accessorFn: row => row.lo_name,
      footer: props => props.column.id,
      cell: props => <StandardCell value={props.getValue() as string} />
    },
    {
      id: "edit",
      header: "",
      footer: props => props.column.id,
      cell: props => (
        <td className="text-center">
          <ChevronRight
            onClick={() => navigate(`/pricing-logs/${props.row.id}`)}
            className="cursor-pointer mx-auto"
          />
        </td>
      )
    }
  ]

  useEffect(() => {
    dispatch(fetchRateflowLogs({ brand_id: brandId, min_created: minCreated }))
  }, [brandId, dispatch, minCreated])

  return (
    <div className="px-6 py-6">
      <Table
        columns={columns}
        data={pricingLogs}
        viewType="table"
        setViewType={() => true}
        renderCard={() => <></>}
        searchPlaceholder="Search logs"
        canChangeType={false}
        extraHeaderElement={
          <ButtonGroup
            className="ml-auto"
            active={period}
            buttons={[
              {
                key: 30,
                label: "30 Days",
                onClick: () => setPeriod(30)
              },
              {
                key: 7,
                label: "7 Days",
                onClick: () => setPeriod(7)
              },
              {
                key: 1,
                label: "1 Day",
                onClick: () => setPeriod(1)
              }
            ]}
          />
        }
      />
    </div>
  )
}

export default BrandDetailsPricingLogs
