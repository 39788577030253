import { DateTime } from "luxon"
import { useEffect, useMemo, useState } from "react"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppDispatch, useAppSelector } from "../../common/hooks/useStore"
import ActivityDetailsFull from "../../common/ui/activity-details-full"
import SidePanel from "../../common/ui/side-panel"
import { RequireRole } from "../../common/validation"
import {
  fetchIntegrations,
  selectIntegrations
} from "../../redux/slices/integrations"
import {
  fetchOrgActivities,
  fetchOrgMembers,
  selectOrgActivities,
  selectOrgBrandsIDs,
  selectOrgDetails,
  selectOrgMembers
} from "../../redux/slices/org"
import { Integration } from "../../types"
import ActivityList from "./ActivityList"
import Charts from "./Charts"
import RecentLOs from "./RecentLOs"
import Stats from "./Stats"

const DashboardPage = () => {
  const dispatch = useAppDispatch()
  const brandsIDs = useAppSelector(selectOrgBrandsIDs)
  const orgDetails = useAppSelector(selectOrgDetails)
  const orgMembers = useAppSelector(selectOrgMembers)
  const orgActivities = useAppSelector(selectOrgActivities)
  const integrations: Integration[] = useAppSelector(selectIntegrations)
  const los = useMemo(
    () => orgMembers.filter(item => item.position === "Loan Officer"),
    [orgMembers]
  )

  const [selectedActivity, setSelectedActivity] = useState<string | null>(null)

  const activityDetails = useMemo(
    () => orgActivities.find(item => item.gid === selectedActivity),
    [orgActivities, selectedActivity]
  )

  useEffect(() => {
    if (orgDetails?.id) {
      dispatch(fetchOrgMembers(orgDetails.id))
      // Sample request
      const now = DateTime.now()
      dispatch(
        fetchOrgActivities({
          groupId: orgDetails.id,
          rangeStart: 0,
          rangeEnd: now.toSeconds()
        })
      )
    }
  }, [dispatch, orgDetails?.id])

  useEffect(() => {
    // Fetch organization members
    if (brandsIDs.length) {
      dispatch(fetchIntegrations(brandsIDs))
    }
  }, [dispatch, brandsIDs])

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.dashboard}>
      <div className="h-full">
        {/* Replace with your content */}

        <div className="py-4 px-6 bg-white flex items-center">
          <h2 className="font-roboto-slab">Dashboard</h2>
          <div className="px-2 text-base text-gray-400">
            &#8226;&nbsp;{orgDetails?.name}
          </div>
        </div>

        {/* Overall Stats */}
        <div className="p-6">
          <Stats
            brands={orgDetails?.brands?.length ?? 0}
            los={los.length}
            integrations={integrations.length ?? 0}
          />
        </div>

        {/* LO & Activity Lists */}
        <div className="p-6 pt-0 flex gap-6">
          <RecentLOs loList={los} />
          <ActivityList
            activityList={orgActivities}
            onClick={setSelectedActivity}
          />
        </div>

        {/* Charts */}
        <div className="p-6 pt-0 flex gap-6">
          <Charts />
        </div>

        {/* Side panel with Activity details */}
        <SidePanel
          title="Activity details"
          open={!!selectedActivity}
          onClose={() => setSelectedActivity(null)}
        >
          <div>
            <ActivityDetailsFull data={activityDetails} />
          </div>
        </SidePanel>
      </div>
    </RequireRole>
  )
}

export default DashboardPage
