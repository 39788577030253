import { FC } from "react"
import { Reviews, ZillowReview } from "../../../../types/Brand"
import ImgZillowLogo from "../../../../assets/icons/zillow_logo.svg"
import ImgStar from "../../../../assets/icons/star.svg"

interface Props {
  reviews?: Reviews
  zillowBrandName?: string
}

const ZillowReviewsList: FC<Props> = ({ reviews, zillowBrandName }) => {
  return (
    <div className="pb-6">
      <div className="text-lg font-bold">Zillow reviews</div>
      {zillowBrandName && (
        <div className="py-4 flex items-center">
          <div className="pr-3">
            <img src={ImgZillowLogo} alt="Zillow Logo" />
          </div>
          <div>
            <div className="font-bold">{zillowBrandName}</div>
          </div>
        </div>
      )}

      <div className="">
        {reviews?.reviews?.length ? (
          reviews.reviews.map(review => {
            const zillowReview = review as ZillowReview
            return (
              <div className="py-4">
                <div className="flex items-center leading-5">
                  <div className="w-5 h-5">
                    <img className="h-full" src={ImgStar} alt="Star" />
                  </div>
                  <div className="font-bold pl-2">{zillowReview.rating}</div>
                  <div className="text-neutral-300">
                    <span className="pl-3 pr-2">.</span>
                    {zillowReview.reviewerName.displayName}
                  </div>
                </div>
                <div className="pt-4">{zillowReview.content}</div>
              </div>
            )
          })
        ) : (
          <div className="py-4">-</div>
        )}
      </div>
    </div>
  )
}

export default ZillowReviewsList
