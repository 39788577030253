import auth from "./auth"
import org from "./org"
import activity from "./activity"
import integrations from "./integrations"
import apiKey from "./api-key"
import brand from "./brand"
import asset from "./asset"
import lead from "./lead"
import others from "./others"
import approvedURLs from "./approved-urls"
import rateflow from "./rateflow"

const bbApi = {
  auth,
  org,
  activity,
  brand,
  integrations,
  apiKey,
  asset,
  lead,
  others,
  approvedURLs,
  rateflow
}

export default bbApi
