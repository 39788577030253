import { FC } from "react"
import { Pencil, Trash2 } from "lucide-react"
import { Brand } from "../../../../types"
import BreadCrumbs from "../../breadcrumbs"
import { Button } from "../../buttons"

interface Props {
  brand: Brand
  onClickEdit: (brandId: string) => void
  onClickDelete: (brandId: string) => void
}

const BrandDetailsHeader: FC<Props> = ({
  brand,
  onClickEdit,
  onClickDelete
}) => {
  return (
    <div className="p-6 bg-white">
      <BreadCrumbs
        pages={[
          {
            name: "Brands",
            href: "/brands",
            current: false
          },
          {
            name: brand.name,
            href: "#",
            current: true
          }
        ]}
      />

      <div className="py-4 flex items-center justify-between">
        <div className="flex items-center">
          <div className="h-12 mr-4 p-2 border border-gray-300 rounded">
            <img
              className="h-full"
              src={brand.logo}
              alt={`${brand.name} Thunbnail`}
            />
          </div>
          <div>
            <h3 className="text-lg font-bold">{brand.name}</h3>
            <div className="text-sm">
              <span>ID: {brand.id}</span>
              <span className="text-gray-400">&nbsp;&bull;&nbsp;</span>
              <span>NMLS {brand.nmls || "N/A"}</span>
              <span className="text-gray-400">&nbsp;&bull;&nbsp;</span>
              <span className="underline">{brand.website}</span>
            </div>
          </div>
        </div>
        <div>
          <Button
            className="mr-2 bg-gray-200"
            onClick={() => onClickEdit(brand.id)}
          >
            <Pencil size={16} />
          </Button>
          <Button
            className="bg-gray-200"
            onClick={() => onClickDelete(brand.id)}
          >
            <Trash2 size={16} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BrandDetailsHeader
