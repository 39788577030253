type Props = {
  value: string
}

const StandardCell = ({ value }: Props) => (
  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 hidden md:table-cell">
    {value}
  </td>
)

export default StandardCell
