import { Table } from "@tanstack/react-table"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { usePagination, DOTS } from "../../hooks/usePagination"

type Props = {
  instance: Table<any>
  totalCount: number
  siblingCount?: number
  viewType?: "grid" | "table"
}

const currentClassName = "z-10 bg-primary-50 border-primary-500 text-base-dark"
const defaultClassName =
  "border-gray-300 text-gray-500 hover:bg-gray-50 bg-white"

const Pagination = ({
  instance,
  totalCount,
  viewType,
  siblingCount = 1
}: Props) => {
  const { pageIndex, pageSize } = instance.getState().pagination

  const paginationRange: (string | number)[] | undefined = usePagination({
    pageIndex,
    totalCount,
    siblingCount
  })

  if (paginationRange?.length < 2) {
    return null
  }

  return (
    <div
      className={`${
        viewType === "grid" ? "" : "bg-white"
      } flex items-center justify-between border-t border-gray-200 py-6`}
    >
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          onClick={() => instance.previousPage()}
          disabled={!instance.getCanPreviousPage()}
          className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Previous</span>
          <ChevronLeft size={16} aria-hidden="true" />
        </button>
        <button
          type="button"
          onClick={() => instance.nextPage()}
          disabled={!instance.getCanNextPage()}
          className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
        >
          <span className="sr-only">Next</span>
          <ChevronRight size={16} aria-hidden="true" />
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-end">
        <div>
          <p className="text-sm text-gray-700">
            Showing{" "}
            <span className="font-medium">
              {(pageIndex + 1) * pageSize - pageSize + 1}
            </span>{" "}
            to <span className="font-medium">{(pageIndex + 1) * pageSize}</span>{" "}
            of{" "}
            <span className="font-medium">
              {instance.getPrePaginationRowModel().rows.length}
            </span>{" "}
            results
          </p>
        </div>
        <div className="ml-4"></div>
        <nav
          className="relative z-0 inline-flex rounded-md -space-x-px"
          aria-label="Pagination"
        >
          <button
            aria-label="Previous"
            type="button"
            onClick={() => instance.previousPage()}
            disabled={!instance.getCanPreviousPage()}
            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-base-dark"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeft size={16} aria-hidden="true" />
          </button>

          {paginationRange.map((pageNumber, i) => {
            if (pageNumber === DOTS) {
              return (
                <span
                  key={DOTS + i}
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                >
                  {DOTS}
                </span>
              )
            }

            return (
              <button
                // add conditional aria-label for accessibility if the current page is the same as the pageNumber
                aria-label={
                  pageNumber === pageIndex + 1
                    ? `Current Page, Page ${pageNumber}`
                    : `Page ${pageNumber}`
                }
                {...(pageNumber === pageIndex + 1 && {
                  "aria-current": "page"
                })}
                key={pageNumber}
                type="button"
                className={`${
                  pageNumber === pageIndex + 1
                    ? currentClassName
                    : defaultClassName
                } relative inline-flex items-center px-4 py-2 border text-sm font-medium hover:text-base-dark`}
                onClick={() => instance.setPageIndex(Number(pageNumber) - 1)}
              >
                {pageNumber}
              </button>
            )
          })}
          <button
            aria-label="Next"
            type="button"
            onClick={() => instance.nextPage()}
            disabled={!instance.getCanNextPage()}
            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 hover:text-base-dark"
          >
            <span className="sr-only">Next</span>
            <ChevronRight size={16} aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  )
}

export default Pagination
