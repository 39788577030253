import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import bbApi from "../../api"
import { Activity } from "../../types"
import { RootState } from "../store"
import { setApiError, setMessage } from "./message"

export interface ActivityState {
  data: Activity[]
  isLoading: boolean
  error: any
}

export const fetchActivitiesByBrand = createAsyncThunk(
  "activity/fetchActivitiesByBrand",
  async ({ brand_id, days }: { brand_id: string; days?: number }, thunkAPI) => {
    try {
      const data = await bbApi.activity.getByBrand(brand_id, days)

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data, brand_id }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const initialState: ActivityState = {
  data: [],
  isLoading: false,
  error: null
}

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setActivities: (state, action: PayloadAction<Activity[]>) => {
      state.data = action.payload
    },
    deleteActivity: (state, action: PayloadAction<{ id: string }>) => {
      state.data = state.data.filter(item => item.id !== action.payload.id)
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchActivitiesByBrand.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchActivitiesByBrand.fulfilled, (state, action) => {
      state.isLoading = false

      if (action.payload) {
        state.data = [
          ...state.data.filter(
            ({ brand_id }) => brand_id !== action.payload?.brand_id
          ),
          ...action.payload.data
        ]
      }
    })
    builder.addCase(fetchActivitiesByBrand.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  }
})

export const { setActivities, deleteActivity } = activitySlice.actions

export const selectActivities = (state: RootState) => state.activity.data

export default activitySlice.reducer
