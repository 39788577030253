import { useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import bbApi from "./api"
import { FullLayout, SingleForm } from "./common/layout"
import { ToastWrapper } from "./common/ui"
import { RequireAuth } from "./common/validation"
import {
  Dashboard,
  Brands,
  Integrations,
  LoanOfficers,
  LoanPrograms,
  LeadSubscriptions,
  PricingLogs,
  NotFound,
  Login,
  Password,
  Logout,
  BrandDetails
} from "./pages"

function App() {
  useEffect(() => {
    ;(window as any).BB = {
      bbApi
    }
  }, [])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <RequireAuth>
              <FullLayout />
            </RequireAuth>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="brands" element={<Brands />} />
          <Route path="brands/:id" element={<BrandDetails />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="loan-officers" element={<LoanOfficers />} />
          <Route path="loan-programs" element={<LoanPrograms />} />
          <Route path="lead-subscriptions" element={<LeadSubscriptions />} />
          <Route path="pricing-logs" element={<PricingLogs />} />
          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="user" element={<SingleForm />}>
          <Route path="login" element={<Login />} />
          <Route path="password" element={<Password />} />
          <Route path="logout" element={<Logout />} />
        </Route>
      </Routes>
      <ToastWrapper />
    </BrowserRouter>
  )
}

export default App
