import { Table } from "@tanstack/react-table"

type Props = {
  children?: React.ReactNode
  className?: string
  instance: Table<any>
  data?: {
    tabs: { label: string; ids: string[] }[]
    onChangeTab: (newTab: string[]) => void
    currentTab: string[]
  }
}

const classNameDefault = ""

const Tabs = ({ data, children, className = classNameDefault }: Props) => (
  <div
    className={`${className} flex items-center bg-white border-b border-gray-200`}
  >
    <div className="flex items-center">
      {data?.tabs.map((tab, index) => (
        <button
          key={index}
          className={`${
            tab.ids[0] === data.currentTab[0]
              ? "border-primary-500 text-base-dark"
              : "text-zinc-400 border-transparent hover:border-primary-300 hover:text-base-dark"
          } h-14 px-6 border-b-[3px] whitespace-nowrap text-sm font-medium leading-5 focus:outline-none focus-visible:border-primary-300 focus:text-base-dark transition duration-150 ease-in-out`}
          onClick={() => {
            const newValue = tab.ids
            data.onChangeTab(newValue)
          }}
        >
          {tab.label}
        </button>
      ))}
    </div>
    <div className="flex align-middle w-full">{children}</div>
  </div>
)

export default Tabs
