import {
  AsyncThunk,
  AnyAction,
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit"
import bbApi from "../../api"
import { ApprovedUrl, ApprovedUrlCreateArg, ApprovedUrls } from "../../types"
import { RootState } from "../store"
import { pushToast } from "./notifications"

type IntState = {
  list: ApprovedUrls
  isLoading: boolean
  isSaving: boolean
}

const initialState: IntState = {
  list: [],
  isLoading: true,
  isSaving: false
}

export const fetchApprovedURLs = createAsyncThunk(
  "approvedURLs/list",
  async (org_id: string) => {
    const data = await bbApi.approvedURLs.list(org_id)

    return { data }
  }
)

export const createApprovedURL = createAsyncThunk(
  "approvedURLs/create",
  async ({ domain_pattern, org_id }: ApprovedUrlCreateArg) => {
    const newURLData = await bbApi.approvedURLs.create(domain_pattern, org_id)

    return { id: newURLData.id, domain_pattern, org_id }
  }
)

export const updateApprovedURL = createAsyncThunk(
  "approvedURLs/update",
  async ({ domain_pattern, org_id, id }: ApprovedUrl) => {
    const newURLData = await bbApi.approvedURLs.update(
      domain_pattern,
      org_id,
      id
    )

    return newURLData.result.domain
  }
)

export const deleteApproverURL = createAsyncThunk(
  "approvedURLs/delete",
  async (domain_pattern_id: string, thunkAPI) => {
    const deletedURL = await bbApi.approvedURLs.delete(domain_pattern_id)

    if (deletedURL.status === "success") {
      thunkAPI.dispatch(
        pushToast({
          title: "Approved URL",
          msg: "Approved URL deleted",
          theme: "success"
        })
      )
    }
    return deletedURL.result.domain
  }
)

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk["pending"]>
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>

function isPendingAction(action: AnyAction): action is PendingAction {
  return (
    (action.type.startsWith("approvedURLs/update") ||
      action.type.startsWith("approvedURLs/create")) &&
    action.type.endsWith("pending")
  )
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return (
    (action.type.startsWith("approvedURLs/update") ||
      action.type.startsWith("approvedURLs/create")) &&
    action.type.endsWith("rejected")
  )
}

export const appovedURLsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchApprovedURLs.pending, state => {
        state.isLoading = true
      })
      .addCase(fetchApprovedURLs.fulfilled, (state, action) => {
        state.isLoading = false
        state.list = action.payload.data
      })

      .addCase(fetchApprovedURLs.rejected, state => {
        state.isLoading = false
      })
      .addCase(deleteApproverURL.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteApproverURL.fulfilled, (state, action) => {
        state.isLoading = false
        state.list = state.list.filter(url => url.id !== action.payload.id)
      })

      .addCase(deleteApproverURL.rejected, state => {
        state.isLoading = false
      })

      .addCase(createApprovedURL.fulfilled, (state, action) => {
        state.list = [...state.list, action.payload]
        state.isSaving = false
      })

      .addCase(updateApprovedURL.fulfilled, (state, action) => {
        // Update the item from the list with the new data
        state.list = state.list.map(item => {
          if (item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
        state.isSaving = false
      })

      .addMatcher(isPendingAction, state => {
        state.isSaving = true
      })
      .addMatcher(isRejectedAction, state => {
        state.isSaving = false
      })
  }
})

export const selectIsSavingURL = (state: RootState) =>
  state.appovedURLs.isSaving
export const selectAppovedURLs = (state: RootState) => state.appovedURLs.list
export const selectIsLoadingAppovedURLs = (state: RootState) =>
  state.appovedURLs.isLoading

export default appovedURLsSlice.reducer
