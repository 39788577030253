import { FC } from "react"
import { Lead } from "../../../../types"

interface Props {
  data: Lead
}

const LeadsCell: FC<Props> = ({ data }) => {
  const { email, first_name, last_name } = data

  return (
    <td className="px-2 py-4">
      <div className="flex items-center">
        <div className="w-12 h-12 flex items-center justify-center bg-gray-600 text-white rounded-full uppercase">
          {first_name.charAt(0)}
          {last_name.charAt(0)}
        </div>
        <div className="px-4">
          <div className="font-bold">{email}</div>
          <div className="text-gray-400 font-light">
            {first_name} {last_name}
          </div>
        </div>
      </div>
    </td>
  )
}

export default LeadsCell
