import { Field, ErrorMessage } from "formik"

type Props = {
  label: string
  name: string
  type?: string
  as?: string
  autoComplete?: string
  placeholder?: string
  beforeLabel?: string | JSX.Element
  beforeIcon?: JSX.Element
  afterLabel?: string | JSX.Element
  afterIcon?: string | JSX.Element
  inputProps?: { [key: string]: any }
}

const typeDefault = "text"

const Input = ({
  label,
  name,
  type = typeDefault,
  as,
  autoComplete,
  placeholder,
  beforeLabel,
  beforeIcon,
  afterLabel,
  afterIcon,
  inputProps = {}
}: Props) => (
  <div>
    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <div className="mt-1 relative flex rounded-md shadow-sm">
      {beforeLabel && (
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          {beforeLabel}
        </span>
      )}
      {beforeIcon && (
        <div className="absolute inset-y-0 left-0 pl-3 py-2 flex items-center pointer-events-none">
          {beforeIcon}
        </div>
      )}
      <Field
        id={name}
        name={name}
        type={type}
        autoComplete={autoComplete}
        placeholder={placeholder}
        as={as}
        className={`appearance-none block w-full px-3 py-2 border border-neutral-200 ${
          beforeLabel ? "rounded-none rounded-r-md" : "rounded-md"
        } ${
          beforeIcon ? "pl-10" : ""
        } placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}
        {...inputProps}
      />
      {afterIcon ? (
        <div className="absolute h-full inset-y-0 right-0 flex py-1.5 pr-1.5">
          {afterIcon}
        </div>
      ) : null}
    </div>
    <ErrorMessage
      name={name}
      component="p"
      className="mt-2 text-sm text-red-600"
    />
  </div>
)

export default Input
