import Badge from "../../../badge"
import RowOptions from "../../../table/row-options"
import { Inspect, Pencil } from "lucide-react"

type Props = {
  row: any
}

const Card = ({ row }: Props) => (
  <div className="flex-1 flex flex-col p-8 relative">
    <img
      className="w-16 h-16 flex-shrink-0 mx-auto rounded-full object-cover"
      src={row.original.picture}
      alt={`${row.original.name} thumbnail`}
    />
    {row.original.brandAdmin ? (
      <Badge.Success className="max-w-min mx-auto -mt-4">Admin</Badge.Success>
    ) : null}
    <h3 className="mt-4 text-base-dark font-medium break-all">
      {row.original.name}
    </h3>
    <dl className="mt-3 flex-grow flex flex-col justify-between">
      <dt className="sr-only">Email</dt>
      <dd className="text-base-dark text-sm break-all">{row.original.email}</dd>
      <dt className="sr-only">ID</dt>
      <dd className="mt-3">
        <span className="text-sm text-neutral-400">ID:{row.original.uid}</span>
      </dd>
      <dt className="sr-only">Brand</dt>
      <dd className="mt-8 flex justify-center">
        <img
          className="h-12 max-h-full"
          src={row.original.logo}
          alt={`${row.original.bank} logo`}
        />
      </dd>
    </dl>
    <RowOptions
      className="absolute top-4 right-4"
      actions={[
        { label: "Masquerade", icon: Inspect, onClick: () => {} },
        { label: "Edit", icon: Pencil, onClick: () => {} }
      ]}
      deleteAction={() => {}}
    />
  </div>
)

export default Card
