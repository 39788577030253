import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { RequireRole } from "../../common/validation"

type Props = {
  title?: string
}

const PricingLogsPage: React.FC<Props> = ({ title }) => {
  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE["pricing-logs"]}>
      <div>
        <div>Pricing logs page</div>
      </div>
    </RequireRole>
  )
}

export default PricingLogsPage
