import { Fragment, useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Copy, X } from "lucide-react"
import {
  deleteAPIKey,
  fetchAPIKey,
  selectAPIKey,
  generateAPIKey,
  selectIsLoadingAPIKey
} from "../../../redux/slices/integrations"
import { useAppDispatch, useAppSelector } from "../../hooks/useStore"
import { LightBtn, LinkBtnDanger, PrimaryBtn } from "../buttons"
import { UserDetails, APIKeyBasic } from "../../../types"
import { selectUserDetails } from "../../../redux/slices/auth"
import If from "../if"

type Props = {
  show: boolean
  toogleModal: (show: boolean) => void
}

const APIKeyModal = ({ show, toogleModal }: Props) => {
  const copyBtn = useRef(null)
  const generateBtn = useRef(null)
  const dispatch = useAppDispatch()
  const user: UserDetails | any = useAppSelector(selectUserDetails)
  const apiKey: APIKeyBasic | undefined = useAppSelector(selectAPIKey)
  const [apiKeyExist, setApiKeyExist] = useState(false)
  const [deleteKeyword, setDeleteKeyword] = useState("")
  const [showDeleteWarning, setDeleteWarning] = useState(false)
  const isLoadingAPIKey: boolean = useAppSelector(selectIsLoadingAPIKey)

  useEffect(() => {
    if (show && !apiKeyExist) {
      dispatch(fetchAPIKey(user.id))
    }
  }, [dispatch, show, apiKeyExist, user])

  const copy = () => {
    if (apiKeyExist) {
      navigator.clipboard.writeText(apiKey?.api_key as string)
    }
  }

  const deleteKey = () => {
    if (apiKeyExist) {
      dispatch(deleteAPIKey(apiKey?.id as string))
    }
  }
  const generateKey = () => {
    dispatch(generateAPIKey(user.id))
  }

  useEffect(() => {
    setApiKeyExist(!!(apiKey && apiKey?.api_key?.length > 0))
  }, [apiKey])

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        onClose={toogleModal}
        initialFocus={apiKeyExist ? copyBtn : generateBtn}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 font-bold text-base-dark mb-8 flex items-center"
                >
                  {showDeleteWarning ? "Delete API Key" : "API Key"}
                  <button
                    type="button"
                    className="hidden sm:block ml-auto bg-white rounded-md primary-alt-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => toogleModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <X size="24" aria-hidden="true" />
                  </button>
                </Dialog.Title>

                <If
                  isLoading={isLoadingAPIKey}
                  condition={apiKeyExist && !showDeleteWarning}
                  then={
                    <>
                      <div className="mb-4">
                        <label htmlFor="api-key" className="sr-only">
                          API key
                        </label>
                        <input
                          className="px-4 py-3 text-base leading-6 border-2 w-full border-zinc-200 rounded-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          value={apiKey?.api_key}
                          readOnly
                        />
                      </div>
                      <PrimaryBtn
                        onClick={copy}
                        className="w-full mb-3"
                        refProp={copyBtn}
                      >
                        <Copy size={20} className="mr-2" /> Copy
                      </PrimaryBtn>
                      <LinkBtnDanger
                        onClick={() => {
                          setDeleteWarning(true)
                        }}
                        className="w-full"
                      >
                        Delete Key
                      </LinkBtnDanger>
                    </>
                  }
                  elseCondition={apiKeyExist && showDeleteWarning}
                  else={
                    <>
                      <div className="mb-4">
                        <label
                          htmlFor="deleteKeyword"
                          className="mb-1 inline-block"
                        >
                          Type "YES" to confirm
                        </label>
                        <input
                          id="deleteKeyword"
                          onChange={e => setDeleteKeyword(e.target.value)}
                          className="px-4 py-3 text-base leading-6 border-2 w-full border-zinc-200 rounded-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          value={deleteKeyword}
                        />
                      </div>
                      <div className="flex justify-end items-center">
                        <LightBtn
                          onClick={() => {
                            setDeleteWarning(false)
                          }}
                          className="mr-3"
                        >
                          Cancel
                        </LightBtn>
                        <PrimaryBtn
                          onClick={deleteKey}
                          disabled={deleteKeyword !== "YES"}
                        >
                          Delete
                        </PrimaryBtn>
                      </div>
                    </>
                  }
                  default={
                    <>
                      <p className="text-center text-gray-400 mb-4">
                        You don't have any API Key generated.
                      </p>
                      <div className="text-center">
                        <PrimaryBtn onClick={generateKey} refProp={generateBtn}>
                          Generate API Key
                        </PrimaryBtn>
                      </div>
                    </>
                  }
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default APIKeyModal
