import api from "../common/utils/axiosApi"
import { APIError, SearchResult } from "../types"

const others = {
  getSearchAnything: async (params: {
    [key: string]: string | number
  }): Promise<SearchResult[] | APIError> => {
    const url = "/api/v1/search-anything"
    const { data } = await api.get(url, {
      params
    })
    return data || []
  }
}

export default others
