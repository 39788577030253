import { FC, MouseEvent } from "react"
import { Download, Pencil } from "lucide-react"
import { Brand } from "../../../../types"
import RowOptions from "../../table/row-options"

type Props = {
  data: Brand
  onClick: (brandId: string) => void
  onClickEdit: (brandId: string) => void
  onClickDelete: (brandId: string) => void
}

const BrandCard: FC<Props> = ({
  data,
  onClick,
  onClickEdit,
  onClickDelete
}) => {
  const handleEdit = (e: MouseEvent) => {
    e.stopPropagation()
    onClickEdit(data.id)
  }

  const handleDelete = (e: MouseEvent) => {
    e.stopPropagation()
    onClickDelete(data.id)
  }

  return (
    <div
      className="flex-1 flex flex-col p-4 relative cursor-pointer"
      onClick={() => onClick(data.id)}
    >
      <div className="h-32 py-6 overflow-hidden">
        <img
          className="flex-shrink-0 mx-auto object-contain max-w-full max-h-full"
          src={data.logo}
          alt={`${data.name} thumbnail`}
        />
      </div>

      <h3 className="mt-4 text-base-dark font-medium">{data.name}</h3>
      <dl className="mt-0 flex-grow flex flex-col justify-between">
        <dt className="sr-only">ID</dt>
        <dd>
          <span className="text-sm text-base-dark">ID: {data.id}</span>
        </dd>
        <dt className="sr-only">Website</dt>
        <dd>
          <span className="text-sm text-neutral-400">{data.website}</span>
        </dd>
      </dl>
      <RowOptions
        className="absolute top-4 right-4"
        actions={[
          { label: "Export LO's", icon: Download, onClick: () => {} },
          { label: "Edit", icon: Pencil, onClick: handleEdit }
        ]}
        deleteAction={handleDelete}
      />
    </div>
  )
}

export default BrandCard
