import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import bbApi from "../../api"
import { Lead } from "../../types"
import { RootState } from "../store"
import { setApiError, setMessage } from "./message"

export interface LeadState {
  data: Lead[]
  isLoading: boolean
  error: any
}

export const fetchLeadsByBrand = createAsyncThunk(
  "lead/fetchByBrand",
  async (brandId: string, thunkAPI) => {
    try {
      const data = await bbApi.lead.get({
        brand_id: brandId
      })

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const initialState: LeadState = {
  data: [],
  isLoading: false,
  error: null
}

const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    addLead: (state, action: PayloadAction<Lead>) => {
      state.data = [...state.data, action.payload]
    },
    deleteLead: (state, action: PayloadAction<{ id: string }>) => {
      state.data = state.data.filter(item => item.id !== action.payload.id)
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchLeadsByBrand.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchLeadsByBrand.fulfilled, (state, action) => {
      state.isLoading = false
      if (action.payload) {
        const newLeadIds = action.payload.data.map(item => item.id)
        state.data = [
          ...state.data.filter(({ id }) => !newLeadIds.includes(id)),
          ...action.payload.data
        ]
      }
    })
    builder.addCase(fetchLeadsByBrand.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  }
})

export const { addLead, deleteLead } = leadSlice.actions

export const selectLeads = (state: RootState) => state.lead.data

export default leadSlice.reducer
