type Props = {
  title?: string
  children?: React.ReactNode
}

const PageHeader = ({ title, children }: Props) => (
  <header className="flex items-center pb-6">
    <h1 className="mr-auto font-serif text-2xl">{title}</h1>
    {children}
  </header>
)

export default PageHeader
