import { FC } from "react"
import htmlToDraft from "html-to-draftjs"
import { Brand } from "../../../../types"
import { convertBoolToEnabled, convertBoolToYesNo } from "../../../utils/brand"

interface Props {
  brand: Brand
}

const BrandOverview: FC<Props> = ({ brand }) => {
  return (
    <div>
      <div className="flex pb-6">
        <div className="flex-1">
          <div className="text-neutral-400">NMLS</div>
          <div className="text-neutral-800">{brand.nmls || "-"}</div>
        </div>
        <div className="flex-1">
          <div className="text-neutral-400">Experience account ID</div>
          <div className="text-neutral-800">{brand.experience_id || "-"}</div>
        </div>
      </div>

      <div className="flex pb-6">
        <div className="flex-1">
          <div className="text-neutral-400">Subscription emails</div>
          <div className="text-neutral-800">
            {convertBoolToEnabled(brand.disable_subscription_emails)}
          </div>
        </div>
        <div className="flex-1">
          <div className="text-neutral-400">Receipt email</div>
          <div className="text-neutral-800">
            {convertBoolToEnabled(brand.disable_receipt_emails)}
          </div>
        </div>
      </div>

      <div className="flex pb-6">
        <div className="flex-1">
          <div className="text-neutral-400">Itemized fees</div>
          <div className="text-neutral-800">
            {convertBoolToEnabled(brand.disable_itemized_fees)}
          </div>
        </div>
        <div className="flex-1">
          <div className="text-neutral-400">Non bank</div>
          <div className="text-neutral-800">
            {convertBoolToYesNo(brand.non_bank)}
          </div>
        </div>
      </div>

      <div className="flex pb-6">
        <div className="flex-1">
          <div className="text-neutral-400">Primary color</div>
          <div className="flex items-center">
            {brand.primary_color && (
              <div
                className="w-6 h-6 mr-1"
                style={{ backgroundColor: brand.primary_color }}
              />
            )}
            <div className="text-neutral-800">{brand.primary_color || "-"}</div>
          </div>
        </div>
        <div className="flex-1">
          <div className="text-neutral-400">Secondary color</div>
          <div className="flex items-center">
            {brand.secondary_color && (
              <div
                className="w-6 h-6 mr-1"
                style={{ backgroundColor: brand.secondary_color }}
              />
            )}
            <div className="text-neutral-800">
              {brand.secondary_color || "-"}
            </div>
          </div>
        </div>
      </div>

      <div className="pb-6">
        <div className="text-neutral-400">About brand</div>
        <div className="text-neutral-800">
          {brand.about
            ? htmlToDraft(brand.about)
                .contentBlocks.map(b => b.getText())
                .join(" ")
            : "-"}
        </div>
      </div>

      <div className="pb-6">
        <div className="text-neutral-400">General disclaimer</div>
        <div className="text-neutral-800">{brand.disclaimer || "-"}</div>
      </div>

      <div className="pb-6">
        <div className="text-neutral-400">Static flyer disclaimer</div>
        <div className="text-neutral-800">{brand.static_disclaimer || "-"}</div>
      </div>

      <div className="pb-6">
        <div className="text-neutral-400">Payment details disclaimer</div>
        <div className="text-neutral-800">
          {brand.payment_details_disclaimer || "-"}
        </div>
      </div>

      <div className="pb-6">
        <div className="text-neutral-400">Lead capture disclaimer</div>
        <div className="text-neutral-800">
          {brand.lead_capture_disclaimer || "-"}
        </div>
      </div>

      <div className="pb-6">
        <div className="text-neutral-400">Loan calculator disclaimer</div>
        <div className="text-neutral-800">
          {brand.loan_calc_disclaimer || "-"}
        </div>
      </div>
    </div>
  )
}

export default BrandOverview
