import { Form, Formik } from "formik"

type Props = {
  initialValues: any
  validationSchema: any
  onSubmit: (formValues: any) => void
  children: React.ReactNode
  className?: string
}

const classNameDefault = ""

const Wrapper = ({
  onSubmit,
  validationSchema,
  initialValues,
  children,
  className = classNameDefault
}: Props) => (
  <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={onSubmit}
  >
    <Form className={className}>{children}</Form>
  </Formik>
)

export default Wrapper
