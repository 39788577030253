import api from "../common/utils/axiosApi"
import { APIError, Brand, OrgMember } from "../types"
import {
  BrandReviews,
  CreateBrandPayload,
  CreateBrandResponse,
  GooglePlace
} from "../types/Brand"

/**
 * Brands API wrapper
 */
const ENDPOINT = "/bank/manage.json"
const ENDPOINT_MEMBERS = "/bank/members.json"
const ENDPOINT_REVIEWS = "/api/v1/reviews/brand"
const ENDPOINT_LO_MANAGE = "/lo-manage.json"
const ENDPOINT_IMPORT_MEMBER = "/api/v1/import-lo-member"
const ENDPOINT_PLACE_AUTOCOMPLETE = "/api/v1/places"

const brand = {
  get: async (): Promise<Brand[] | APIError> => {
    const { data } = await api.get(ENDPOINT)
    return data
  },
  getById: async (id: string): Promise<Brand | APIError> => {
    const params = { id }
    const { data } = await api.get(ENDPOINT, { params })
    return data
  },
  add: async (brandData: CreateBrandPayload): Promise<CreateBrandResponse> => {
    if (!brandData.name) {
      throw new Error("Brand name is missing")
    }
    const { data } = await api.post(ENDPOINT, brandData)
    return data
  },
  update: async (brandData: Brand): Promise<CreateBrandResponse | APIError> => {
    if (!brandData.id) {
      throw new Error("Brand id is missing")
    }
    const { data } = await api.post(ENDPOINT, brandData)
    return data
  },
  delete: async (id: string) => {
    const { data } = await api.delete(`${ENDPOINT}?id=${id}`)
    return data
  },
  getMembers: async (brandId: string): Promise<OrgMember[] | APIError> => {
    const { data } = await api.get(ENDPOINT_MEMBERS, {
      params: { id: brandId }
    })
    return data?.members ? data.members : data
  },
  moveMember: async (
    loid: string,
    newBrandId: string
  ): Promise<any | APIError> => {
    if (!loid) {
      throw new Error("LO id is missing")
    }
    if (!newBrandId) {
      throw new Error("New brand id is missing")
    }
    const moveMemberData = {
      loid,
      bank_nid: newBrandId
    }

    const { data } = await api.post(ENDPOINT_LO_MANAGE, moveMemberData)
    return data
  },
  importMember: async (row: any, brandId: string): Promise<any | APIError> => {
    if (!brandId) {
      throw new Error("Brand id is missing")
    }
    const postData = {
      row,
      brand_id: brandId
    }

    const { data } = await api.post(ENDPOINT_IMPORT_MEMBER, postData)
    return data
  },
  reviews: async (params: any): Promise<BrandReviews | APIError> => {
    if (
      !params.id &&
      !params.place_id &&
      !params.zillow_brand_name &&
      !params.experience_id
    ) {
      throw new Error("Required params are missing")
    }
    const { data } = await api.get(ENDPOINT_REVIEWS, { params })
    return data
  },
  find: async (params: any): Promise<any | APIError> => {
    const { data } = await api.get(ENDPOINT, { params })
    return data
  },
  placeAutoComplete: async (params: {
    input: string
  }): Promise<any | APIError> => {
    const { data } = await api.get(ENDPOINT_PLACE_AUTOCOMPLETE, { params })
    return data
  },
  getPlace: async (params: {
    place_id: string
  }): Promise<GooglePlace | APIError> => {
    const { data } = await api.get(ENDPOINT_PLACE_AUTOCOMPLETE, { params })
    return data
  }
}

export default brand
