import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import bbApi from "../../api"
import { PricingLog } from "../../types"
import { RootState } from "../store"
import { setApiError, setMessage } from "./message"

export interface RateflowState {
  logs: PricingLog[]
  isLoading: boolean
  error: any
}

export const fetchRateflowLogs = createAsyncThunk(
  "rateflow/fetchRateflowLogs",
  async (
    { brand_id, min_created }: { brand_id: string; min_created: number },
    thunkAPI
  ) => {
    try {
      const data = await bbApi.rateflow.logSearch({
        bank_id: brand_id,
        min_created
      })

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { ...data, brand_id }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

const initialState: RateflowState = {
  logs: [],
  isLoading: false,
  error: null
}

const rateflowSlice = createSlice({
  name: "rateflow",
  initialState,
  reducers: {
    setRateflowLogs: (state, action: PayloadAction<any>) => {
      state.logs = action.payload
    },
    deleteRateflowLogs: (state, action: PayloadAction<{ id: string }>) => {
      state.logs = state.logs.filter(item => item.id !== action.payload.id)
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchRateflowLogs.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchRateflowLogs.fulfilled, (state, action) => {
      state.isLoading = false

      if (action.payload) {
        state.logs = [
          ...state.logs.filter(
            ({ brand_id }) => brand_id !== action.payload.brand_id
          ),
          ...action.payload.data
        ]
      }
    })
    builder.addCase(fetchRateflowLogs.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  }
})

export const { setRateflowLogs, deleteRateflowLogs } = rateflowSlice.actions

export const selectRateflowLogs = (state: RootState) => state.rateflow.logs

export default rateflowSlice.reducer
