import { DateTime } from "luxon"
import { UserIcon } from "@heroicons/react/outline"
import { OrgActivity } from "../../../types"

interface Props {
  data: OrgActivity
  onClick: () => void
}

const DashboardActivityCard: React.FC<Props> = ({ data, onClick }) => {
  return (
    <div
      className="flex justify-between border-b border-gray-300 py-4 cursor-pointer"
      onClick={onClick}
    >
      <div className="w-5 h-5 mt-1 rounded-full bg-gray-300">
        <UserIcon width={20} color="white" />
      </div>
      <div className="flex-1 px-4">
        <div className="text-lg">
          {data.details.lead?.first_name}&nbsp;{data.details.lead?.last_name}
        </div>
        <div className="text-sm text-gray-400">
          {data.details.source_details?.title ?? "Placeholder title"}
        </div>
      </div>
      <div className="text-gray-400">
        {DateTime.fromMillis(parseInt(data.timestamp, 10)).toLocaleString(
          DateTime.DATETIME_MED
        )}
      </div>
    </div>
  )
}

export default DashboardActivityCard
