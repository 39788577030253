import { ChangeEvent, FC } from "react"
import { classNames } from "../../utils"

export interface TabProps {
  id: string
  label: string
}

interface Props {
  tabs: TabProps[]
  currentTab: string
  onChange: (tabId: string) => void
  className?: string
}

const Tabs: FC<Props> = ({ tabs, currentTab, onChange, className = "" }) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value)
  }

  return (
    <div className={className}>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={tabs.find(tab => tab.label === currentTab)?.label}
          onChange={handleChange}
        >
          {tabs.map(tab => (
            <option key={tab.id}>{tab.label}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="px-6 border-b border-gray-200">
          <nav className="-mb-px flex space-x-2" aria-label="Tabs">
            {tabs.map(tab => (
              <div
                key={tab.id}
                className={classNames(
                  tab.id === currentTab
                    ? "border-teal-500 text-gray-700"
                    : "border-transparent text-gray-300 hover:text-gray-500 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-4 border-b-2 font-medium text-sm cursor-pointer"
                )}
                onClick={() => onChange(tab.id)}
              >
                {tab.label}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Tabs
