import DashboardActivityCard from "../../common/ui/dashboard-activity-card"
import { OrgActivity } from "../../types"

interface Props {
  activityList: OrgActivity[]
  onClick: (itemKey: string) => void
}

const ActivityList: React.FC<Props> = ({ activityList, onClick }) => {
  return (
    <div className="bg-white flex-1 p-6 rounded-md">
      <div className="text-base font-semibold">Activity</div>
      <div className="py-6">
        {activityList.length
          ? activityList.map(item => (
              <DashboardActivityCard
                key={item.event_id}
                data={item}
                onClick={() => onClick(item.gid)}
              />
            ))
          : "No activities found."}
      </div>
    </div>
  )
}

export default ActivityList
