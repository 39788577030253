import { useEffect, useMemo, useState } from "react"
import { Plus } from "lucide-react"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppDispatch, useAppSelector } from "../../common/hooks/useStore"
import { PageHeader, PrimaryBtn } from "../../common/ui"
import { RequireRole } from "../../common/validation"
import {
  deleteBrand,
  fetchBrands,
  selectBrands
} from "../../redux/slices/brand"
import SearchBar from "../../common/ui/table/toolbar/search-bar"
import SimpleSorter from "../../common/ui/simple-sorter"
import { SortDirection } from "@tanstack/react-table"
import BrandCard from "../../common/ui/brands/card"
import { useNavigate } from "react-router-dom"
import { Brand } from "../../types"
import ManageBrand from "../../common/ui/brands/manage"
import BBDialog from "../../common/ui/dialog"

type Props = {
  title?: string
}

const BrandsPage: React.FC<Props> = ({ title }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const brands = useAppSelector(selectBrands)

  const [filter, setFilter] = useState<string>("")
  const [sortDir, setSortDir] = useState<SortDirection>("asc")
  const [manageBrandVisible, setManageBrandVisible] = useState<boolean>(false)
  const [selectedBrand, setSelectedBrand] = useState<Brand>()
  const [brandToDelete, setBrandToDelete] = useState<string>()

  // Sort & filter brands
  const sortedBrands = useMemo(
    () =>
      [...brands]
        .sort((a, b) =>
          (sortDir === "asc" ? a.name > b.name : a.name < b.name) ? 1 : -1
        )
        .filter(
          item =>
            item.name.toLowerCase().includes((filter ?? "").toLowerCase()) ||
            item.id
              .toString()
              .toLowerCase()
              .includes((filter ?? "").toLowerCase())
        ),
    [brands, filter, sortDir]
  )

  const openManageBrandSidebar = () => setManageBrandVisible(true)
  const closeManageBrandSidebar = () => setManageBrandVisible(false)
  const clearBrandToDelete = () => setBrandToDelete(undefined)

  const onSearch = (value: string | number) => {
    setFilter(value.toString())
  }
  const onSort = (newDir: SortDirection) => {
    setSortDir(newDir)
  }

  const onClickCard = (brandId: string) => {
    navigate(`/brands/${brandId}`)
  }

  const onClickEdit = (brandId: string) => {
    setSelectedBrand(brands.find(b => b.id === brandId))
    setManageBrandVisible(true)
  }

  const onClickDelete = (brandId: string) => {
    setBrandToDelete(brandId)
  }

  const handleDelete = () => {
    if (brandToDelete) {
      dispatch(
        deleteBrand({
          id: brandToDelete
        })
      )

      clearBrandToDelete()
    }
  }

  useEffect(() => {
    dispatch(fetchBrands())
  }, [dispatch])

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.brands}>
      <>
        <div className="p-6 bg-white">
          <PageHeader title="Brands">
            <PrimaryBtn onClick={openManageBrandSidebar}>
              <Plus size={16} />
              &nbsp;New Brand
            </PrimaryBtn>
          </PageHeader>
          <div>
            <SearchBar
              className="pb-4"
              globalFilter={filter}
              onSearch={onSearch}
              searchPlaceholder="Search Brands"
            >
              <SimpleSorter
                className="ml-4"
                sortDir={sortDir}
                changeSort={onSort}
              />
            </SearchBar>
          </div>
        </div>
        <div className="px-6 py-2">
          <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-4">
            {sortedBrands.length ? (
              sortedBrands.map(brand => (
                <li
                  key={brand.id}
                  className="col-span-1 flex flex-col bg-white rounded-lg shadow divide-y divide-gray-200"
                >
                  <BrandCard
                    data={brand}
                    onClick={onClickCard}
                    onClickEdit={onClickEdit}
                    onClickDelete={onClickDelete}
                  />
                </li>
              ))
            ) : (
              <div>No brands found.</div>
            )}
          </ul>
        </div>

        <ManageBrand
          open={manageBrandVisible}
          data={selectedBrand}
          onClose={closeManageBrandSidebar}
        />

        <BBDialog
          open={!!brandToDelete}
          onClose={clearBrandToDelete}
          title="Delete brand"
          body="By doing this all the information stored in the system about this brand will be deleted. Are you sure?"
          okLabel="Yes, Delete"
          cancelLabel="Cancel"
          onOk={handleDelete}
          onCancel={clearBrandToDelete}
        />
      </>
    </RequireRole>
  )
}

export default BrandsPage
