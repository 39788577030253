import CountUp from "react-countup"
import { Link } from "react-router-dom"

interface Props {
  title: string
  stat: number
  index?: number
  icon: JSX.Element
  linkLabel: string
  linkHref: string
}

const INDEX_DEFAULT = 0

const DashboardStatCard: React.FC<Props> = ({
  title,
  index = INDEX_DEFAULT,
  stat,
  icon,
  linkLabel,
  linkHref
}) => {
  return (
    <div className="flex-1 rounded-md bg-white">
      <div className="p-6 flex items-center">
        <div className="w-16 h-16 flex items-center justify-center rounded-full bg-blue-400">
          {icon}
        </div>
        <div className="pl-6">
          <div className="text-sm uppercase">{title}</div>
          <div className="text-3xl font-bold">
            <CountUp delay={index * 0.2} end={stat} duration={1} />
          </div>
        </div>
      </div>
      <div className="px-6 py-4 border-t border-gray-300">
        <Link className="text-blue-300 text-sm" to={linkHref}>
          {linkLabel}
        </Link>
      </div>
    </div>
  )
}

export default DashboardStatCard
