import { Dialog, Transition } from "@headlessui/react"
import { ArrowUpRight, X } from "lucide-react"
import { Fragment, useEffect, useRef, useState } from "react"
import { selectPreviewModal } from "../../../redux/slices/integrations"
import { useAppSelector } from "../../hooks/useStore"
import ToggleBtn from "../toggle-btn"

type Props = {
  onClose: () => void
}

const PreviewModal = ({ onClose }: Props) => {
  const toggleViewBtn = useRef(null)
  const [previewType, togglePreviewType] = useState("desktop")
  const [widthClassName, setWidthClassName] = useState("")

  const { app_key, isOpen, title } = useAppSelector(selectPreviewModal)

  useEffect(() => {
    const className =
      previewType === "desktop" ? "sm:w-100 h-2/3 sm:max-w-5xl" : "sm:w-96"

    setWidthClassName(className)
  }, [previewType])

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[100]"
        onClose={onClose}
        initialFocus={toggleViewBtn}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`p-6 sm:w-5/6 h-2/3 sm:max-w-5xl overflow-hidden rounded-sm bg-white text-left align-middle shadow-xl transition-all`}
              >
                <Dialog.Title
                  as="h3"
                  className="text-xl leading-6 mb-3 flex items-center"
                >
                  <div>
                    {title} <span className="mr-3">(Preview)</span>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`http://bankingbridge.localhost/lo-landing-page?key=${app_key}`}
                      className="hover:text-primary-500 transition-colors inline-flex"
                    >
                      <small className="hidden sm:inline-block">
                        View live <span className="sr-only">in new tab</span>
                      </small>
                      <ArrowUpRight size={24} />
                    </a>
                  </div>
                  <ToggleBtn
                    showLabel
                    className="ml-auto hidden sm:flex"
                    value={previewType}
                    onClick={togglePreviewType}
                    options={[
                      { label: "Desktop", value: "desktop" },
                      { label: "Mobile", value: "mobile" }
                    ]}
                  />
                  <div className="hidden sm:block ml-3">
                    <button
                      type="button"
                      className="bg-white rounded-md primary-alt-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <X size="24" aria-hidden="true" />
                    </button>
                  </div>
                </Dialog.Title>
                <div className={`${widthClassName} transform m-auto`}>
                  <iframe
                    title="Embed Preview"
                    className="w-full h-[32rem] "
                    src={`${process.env.REACT_APP_IFRAME_PREVIEW_HOST}/lo-landing-page?key=${app_key}`}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PreviewModal
