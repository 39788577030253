import { AlertCircle, AlertTriangle, CheckCircle, Info } from "lucide-react"
import { ToastTheme } from "../../../types"

type Props = {
  theme: ToastTheme
}

const themes = {
  danger: {
    color: "red",
    Icon: AlertCircle
  },
  warning: {
    color: "yellow",
    Icon: AlertTriangle
  },
  success: {
    Icon: CheckCircle,
    color: "green"
  },
  info: {
    Icon: Info,
    color: "blue"
  }
}

const ToastIcon = ({ theme }: Props) => {
  const { color, Icon: IconComponent } = themes[theme ?? "success"]
  return (
    <div className={`flex-shrink-0 text-${color}-400`}>
      <IconComponent size={24} />
    </div>
  )
}
export default ToastIcon
