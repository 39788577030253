/**
 * LO rates API wrapper
 */

import api from "../common/utils/axiosApi"

const ENDPOINT = "/rateflow/manage.json"
const PRICE_ENDPOINT = "/rateflow/price.json"
const LOG_ENDPOINT = "/api/v1/rateflow-log"
const COMPARE_ENDPOINT = "/api/v1/rateflow-compare"

const rateflow = {
  get: async (params: any) => {
    // check if params empty
    if (!Object.keys(params).length) {
      const { data } = await api.get(ENDPOINT, { params: { default: true } })
      return data
    }
    const { data } = await api.get(ENDPOINT, params)
    return data
  },
  // rateflowData={
  //      owner*
  //      loid
  //      id*
  //      settings:{
  //          target_price
  //          cache_ttl
  //      }
  //      pricing_set_id
  //      bank_id
  //      name
  // }
  update: async (rateflowData: any) => {
    if (!rateflowData.id) {
      throw new Error("rateflow id missing")
    }

    const { data } = await api.post(ENDPOINT, rateflowData)
    return data
  },
  // rateflowData={
  //      owner
  //      loid*
  //      id
  //      settings:{
  //          target_price
  //          cache_ttl
  //      }
  //      pricing_set_id
  //      bank_id
  //      name
  // }
  add: async (rateflowData: any) => {
    const { data } = await api.post(ENDPOINT, rateflowData)
    return data
  },
  // params = {
  //      id*
  //      loid
  //      include_trend
  //      max_results
  //      ignore_cache
  //      list_price
  //      min_credit
  // }
  price: async (params: any) => {
    if (!params.id && !params.loid) {
      throw new Error("rateflow id or loid is required")
    }
    const { data } = await api.get(PRICE_ENDPOINT, params)
    return data
  },
  // params = {
  //      id*
  // 		credit_score
  // 		loan_purpose
  // 		amortization_type
  // 		arm_term
  // 		aus
  // 		lock_period
  // 		loan_amount
  // 		down_payment
  // 		lead_source
  // 		loan_term
  // 		loan_type
  // 		list_price
  // 		county
  // 		street
  // 		state
  //		zipcode
  // }
  priceCustom: async (params: any) => {
    if (!params.id && !params.loid) {
      throw new Error("rateflow id missing")
    }

    const { data } = await api.post(PRICE_ENDPOINT, params)
    return data
  },
  log: async (log_id: string | string[]) => {
    const id_param = Array.isArray(log_id) ? log_id.join(",") : log_id
    const { data } = await api.get(LOG_ENDPOINT, {
      params: { id: id_param, include_request: true, include_cards: true }
    })
    return data
  },
  compare: async (quote_id: string) => {
    const { data } = await api.get(COMPARE_ENDPOINT, { params: { quote_id } })
    return data
  },
  logSearch: async (params?: any) => {
    const { data } = await api.get(LOG_ENDPOINT, { params })
    return data
  }
}

export default rateflow
