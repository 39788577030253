import React from "react"
import { useNavigate } from "react-router-dom"
import { classNames } from "../../utils"

interface Item {
  current: boolean
  name: string
  href?: string
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  onClick?: () => void
}

interface Props {
  isFull: boolean
  item: Item
}

const NavbarItem: React.FC<Props> = ({
  isFull,
  item: { name, href, current, icon: Icon, onClick }
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (onClick) {
      return onClick()
    }

    if (href) {
      navigate(href)
    }
  }

  return (
    <div
      className={classNames(
        current
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        isFull ? "justify-start" : "justify-center",
        "group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer whitespace-nowrap overflow-hidden"
      )}
      onClick={handleClick}
    >
      <Icon
        className={classNames(
          current ? "text-gray-300" : "text-gray-400 group-hover:text-gray-300",
          isFull ? "mr-3" : "mr-0",
          "flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {isFull ? name : ""}
    </div>
  )
}

export default NavbarItem
