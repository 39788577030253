import { useState } from "react"
import { Pencil, Trash2 } from "lucide-react"
import { LinkBtn } from "../buttons"
import If from "../if"
import InputURL from "./input-url"
import { ApprovedUrl } from "../../../types"
import {
  deleteApproverURL,
  updateApprovedURL
} from "../../../redux/slices/appoved-urls"
import { useAppDispatch } from "../../hooks/useStore"

type Props = {
  url: ApprovedUrl
}

const ListItem = ({ url }: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editableURL, setEditableURL] = useState(url.domain_pattern)
  const dispatch = useAppDispatch()

  const onSubmit = () => {
    dispatch(
      updateApprovedURL({
        id: url.id,
        domain_pattern: editableURL,
        org_id: url.org_id
      })
    )
    setIsEditing(false)
  }

  return (
    <li className="flex items-center justify-between py-1 border-b border-zinc-200">
      <If
        condition={isEditing}
        then={
          <>
            <InputURL
              resetOnBlur
              url={editableURL}
              onBlur={() => setIsEditing(false)}
              onChange={setEditableURL}
              onSubmit={onSubmit}
            />
          </>
        }
        else={
          <>
            <span className="text-base-dark truncate pr-3">
              {url.domain_pattern}
            </span>
            <div className="flex items-center">
              <LinkBtn
                theme="alt"
                className="text-sm py-2 px-2"
                onClick={() => {
                  setIsEditing(true)
                }}
              >
                <Pencil size={14} />
              </LinkBtn>
              <LinkBtn
                theme="alt"
                className="text-sm py-2 px-2"
                onClick={() => {
                  dispatch(deleteApproverURL(url.id))
                }}
              >
                <Trash2 size={14} />
              </LinkBtn>
            </div>
          </>
        }
      />
    </li>
  )
}

export default ListItem
