import { AlertCircle, CheckCircle2, AlertTriangle, Info } from "lucide-react"

type Props = {
  message: string
  title?: string
  className: string
  theme: "danger" | "success" | "warning" | "info"
}

const themes = {
  danger: {
    color: "red",
    Icon: AlertCircle
  },
  warning: {
    color: "yellow",
    Icon: AlertTriangle
  },
  success: {
    Icon: CheckCircle2,
    color: "green"
  },
  info: {
    Icon: Info,
    color: "blue"
  }
}

const AlertWrapper = ({ message, theme, className, title }: Props) => {
  const { color, Icon: IconComponent } = themes[theme]

  return (
    <div className={`rounded-md p-4 bg-${color}-50 ${className}`} role="alert">
      <div className="flex">
        <div className="flex-shrink-0">
          <IconComponent
            className={`h-5 w-5 text-${color}-400`}
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          {title ? (
            <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>
          ) : null}
          <p className={`text-sm text-${color}-700`}>{message}</p>
        </div>
      </div>
    </div>
  )
}

export default AlertWrapper
