import { useEffect, useMemo, useState } from "react"
import Navbar from "../navbar"
import Header from "../header"
import { Outlet, useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../hooks/useStore"
import { fetchOrgDetails } from "../../../redux/slices/org"
import { getMe, selectIsLoggedIn, selectUser } from "../../../redux/slices/auth"
import { ALLOWED_ROLES_BY_ROUTE, NavbarItems } from "../../constants"

const FullLayout = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const user = useAppSelector(selectUser)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed)

  const navItems = useMemo(
    () =>
      NavbarItems.filter(({ key }) =>
        (user?.user.roles ?? []).some(role =>
          ALLOWED_ROLES_BY_ROUTE[key].includes(role)
        )
      ),
    [user?.user.roles]
  )
  useEffect(() => {
    if (user?.user.id) {
      dispatch(getMe()).then((res: any) => {
        // if user is not loggein in, redirect to login page
        if (!res.payload?.userDetails) {
          navigate("/user/logout", { replace: true })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, user?.user.id])

  useEffect(() => {
    if (isLoggedIn) {
      // Fetch organization details
      dispatch(fetchOrgDetails())
    }
  }, [dispatch, isLoggedIn])

  return (
    <div className="h-full">
      <Navbar
        items={navItems}
        sidebarOpen={sidebarOpen}
        sidebarCollapsed={sidebarCollapsed}
        setSidebarOpen={setSidebarOpen}
        toggleSidebarCollapsed={toggleSidebarCollapsed}
      />
      <div
        className={`${
          sidebarCollapsed ? "md:pl-24" : "md:pl-64"
        } h-full flex flex-col flex-1 transition-all bg-primary-100`}
      >
        <Header setSidebarOpen={setSidebarOpen} />
        <main className="bg-primary-100 flex-1">
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default FullLayout
