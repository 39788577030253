/**
  Approved URLs API wrapper

	JSON endpoint: /org/domains.json
  POST /org/domains.json - Add new approved URL
  {
    "domain_pattern": "https://google.com/*",
    "org_id": <org_id>
  }
  POST /org/domains.json - Update approved URL
  {
    "domain_pattern": "https://google.com/*",
    "org_id": <org_id>,
    "id": <domain_pattern_id>
  }
	GET  /org/domains.json?org_id=<org_id> - returns a list of approved URLs
  DELETE /org/domains.json?id=<domain_pattern_id> - deletes an approved URL
**/

import api from "../common/utils/axiosApi"
import {
  ApprovedUrlCreateResponse,
  ApprovedUrlDeleteResponse,
  ApprovedUrlResponse
} from "../types"

const ENDPOINT = "/org/domains.json"

const approvedURLs = {
  list: async (org_id: string): Promise<ApprovedUrlResponse> => {
    const { data } = await api.get<ApprovedUrlResponse>(
      `${ENDPOINT}?org_id=${org_id}`
    )
    return data
  },
  create: async (
    domain_pattern: string,
    org_id: string
  ): Promise<ApprovedUrlCreateResponse> => {
    if (!domain_pattern || !org_id) {
      throw new Error("aregument missing")
    }
    const { data } = await api.post<ApprovedUrlCreateResponse>(ENDPOINT, {
      domain_pattern,
      org_id
    })
    return data
  },
  update: async (
    domain_pattern: string,
    org_id: string,
    domain_pattern_id: string
  ): Promise<ApprovedUrlDeleteResponse> => {
    if (!domain_pattern || !org_id || !domain_pattern_id) {
      throw new Error("aregument missing")
    }
    const { data } = await api.post<ApprovedUrlDeleteResponse>(ENDPOINT, {
      domain_pattern,
      org_id,
      id: domain_pattern_id
    })
    return data
  },
  delete: async (
    domain_pattern_id: string
  ): Promise<ApprovedUrlDeleteResponse> => {
    if (!domain_pattern_id) {
      throw new Error("domain_pattern_id missing")
    }
    const { data } = await api.delete<ApprovedUrlDeleteResponse>(
      ENDPOINT + "?id=" + domain_pattern_id
    )
    return data
  }
}

export default approvedURLs
