import { useCallback, useMemo } from "react"
import { Menu, PlusCircle, Inspect } from "lucide-react"
import { UserCircleIcon } from "@heroicons/react/solid"
import { useAppDispatch, useAppSelector } from "../../hooks/useStore"
import {
  addNewDropdownItems,
  profileDropdownItems
} from "../../constants/navbar"
import Dropdown, { DropdownItem } from "../../ui/dropdown"
import { deleteMasquerade } from "../../../redux/slices/auth"
import { useNavigate } from "react-router-dom"
import SearchAnything from "./SearchAnything"

type Props = {
  setSidebarOpen: (sidebarOpen: boolean) => void
}

const Header = ({ setSidebarOpen }: Props) => {
  const dispatch = useAppDispatch()

  const auth = useAppSelector(state => {
    return state.auth
  })

  const user = auth.user?.user
  const isMasq = user?.is_masq && user.is_masq !== "0"

  const delMasquerade = useCallback(() => {
    dispatch(deleteMasquerade())
  }, [dispatch])

  const delMasqItem = useMemo(
    () => ({
      name: "Switch Back",
      href: "#",
      icon: Inspect,
      onClick: delMasquerade
    }),
    [delMasquerade]
  )

  const profileItems = useMemo(
    () =>
      isMasq ? [delMasqItem, ...profileDropdownItems] : profileDropdownItems,
    [delMasqItem, isMasq]
  )

  const navigate = useNavigate()

  const handleClick = (item: DropdownItem) => {
    return item.onClick ? item.onClick(item) : navigate(item.href ?? "#")
  }

  if (!user) return null

  return (
    <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Menu size={20} aria-hidden="true" />
      </button>
      <div className="flex-1 px-4 flex justify-between">
        <div className="flex-1 flex">
          <SearchAnything />
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          <Dropdown
            items={addNewDropdownItems}
            menuBtn={
              <div className="flex items-center">
                <PlusCircle size={20} className="mr-2 text-gray-400" />
                <span>Add New</span>
              </div>
            }
            handleClick={handleClick}
          />

          <Dropdown
            items={profileItems}
            menuBtn={
              <>
                <span className="sr-only">Open user menu</span>
                {user.picture ? (
                  <img
                    className="h-8 w-8 rounded-full object-cover"
                    src={user.picture}
                    alt="user thumbnail"
                  />
                ) : (
                  <UserCircleIcon width="2rem" color="black" />
                )}
              </>
            }
            handleClick={handleClick}
          />
        </div>
      </div>
    </div>
  )
}
export default Header
