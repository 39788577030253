import Wrapper from "./wrapper"

type Props = {
  message: string
  className: string
  title?: string
}

const Danger = (props: Props) => {
  return <Wrapper {...props} theme="danger" />
}

const Info = (props: Props) => {
  return <Wrapper {...props} theme="info" />
}

const Warning = (props: Props) => {
  return <Wrapper {...props} theme="warning" />
}

const Success = (props: Props) => {
  return <Wrapper {...props} theme="success" />
}

const Alert = { Danger, Info, Warning, Success }

export default Alert
