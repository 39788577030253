import {
  Search,
  Users,
  LayoutDashboard,
  Landmark,
  Code2,
  DollarSign,
  Monitor,
  AtSign,
  Contact
} from "lucide-react"
import { FC, MouseEvent, useEffect, useState } from "react"
import bbApi from "../../../api"
import { selectOrgDetails } from "../../../redux/slices/org"
import { SearchResult } from "../../../types"
import { useAppSelector } from "../../hooks/useStore"
import DebouncedInput from "../../ui/form/debouncedInput"
import BBModal from "../../ui/modal"
import { addRecentSearch, getRecentSearches } from "../../utils/localStorage"

interface Props {}

const SearchAnything: FC<Props> = () => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [query, setQuery] = useState<string>("")
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])

  const orgDetails = useAppSelector(selectOrgDetails)

  const recentSearches = getRecentSearches()

  const openModal = () => setModalVisible(true)
  const closeModal = () => {
    setQuery("")
    setModalVisible(false)
  }

  const onClickInput = (e: MouseEvent) => {
    e.preventDefault()
    openModal()
  }

  const onClickItem = (item: SearchResult) => {
    addRecentSearch(item)
  }

  const getIcon = (type: string) => {
    switch (type) {
      case "integration":
        return <Code2 size={16} />
      case "subscription":
        return <AtSign size={16} />
      case "loan_officer":
        return <Users size={16} />
      case "brand":
        return <Landmark size={16} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (query) {
      bbApi.others
        .getSearchAnything({ q_str: query, org_id: orgDetails?.id ?? "" })
        .then(res => {
          if ("status" in res) {
            setSearchResults([])
          } else {
            setSearchResults(res)
          }
        })
        .catch(() => setSearchResults([]))
    } else {
      setSearchResults([])
    }
  }, [orgDetails?.id, query])

  return (
    <div className="relative w-full flex items-center text-gray-400 focus-within:text-gray-600">
      <label htmlFor="search-field" className="sr-only">
        Search
      </label>
       
      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
        <Search className="text-neutral-400" size={20} aria-hidden="true" />
      </div>
      <input
        id="search-field"
        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
        placeholder="Search for anything"
        type="search"
        name="search"
        autoComplete="off"
        onClick={onClickInput}
      />
      <BBModal open={modalVisible} onClose={closeModal}>
        <>
          <div className="relative w-full flex items-center text-gray-400 focus-within:text-gray-600 border-b border-neutral-200 p-2">
            <div className="absolute inset-y-0 left-4 flex items-center pointer-events-none">
              <Search
                className="text-neutral-400"
                size={20}
                aria-hidden="true"
              />
            </div>
            <DebouncedInput
              className="block w-full h-full pl-10 pr-3 py-2 border-none text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
              value={query}
              onChange={val => setQuery(val.toString())}
            />
          </div>
          <div className="h-80 overflow-auto">
            {searchResults.length ? (
              <>
                {searchResults.map((result, idx) => (
                  <div
                    key={`${result.id}-${result.item}`}
                    className={`cursor-pointer py-2 px-6 font-medium text-sm border-b border-neutral-100 ${
                      idx % 2 ? "bg-white" : "bg-neutral-100"
                    }`}
                    onClick={() => onClickItem(result)}
                  >
                    {result.item} - {result.type}
                  </div>
                ))}
              </>
            ) : query ? (
              <div className="p-4 text-sm text-neutral-400 font-medium">
                No results found.
              </div>
            ) : (
              <div className="p-4">
                <div className="text-sm text-neutral-400 font-bold">
                  Recent searches
                </div>
                <div className="py-2">
                  {recentSearches.length ? (
                    recentSearches.map(result => (
                      <div
                        key={result.id}
                        className="flex items-center py-2 text-sm cursor-pointer"
                        onClick={() => onClickItem(result)}
                      >
                        <div className="text-neutral-400">
                          {getIcon(result.type)}
                        </div>
                        <div className="px-2 text-neutral-400 capitalize">
                          {result.type?.split("_").join(" ")}:
                        </div>
                        <div className="">{result.item}</div>
                      </div>
                    ))
                  ) : (
                    <div className="py-2 text-sm text-neutral-400 font-medium">
                      No recent seaches found.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      </BBModal>
    </div>
  )
}

export default SearchAnything
