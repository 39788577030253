/**
  Organization API wrapper

	JSON endpoint: /org/manage.json
	GET /org/manage.json - list all orgs belonging to current user
	GET /org/manage.json?id={org_id} - returns org details and a list of brands that belong to the org
	POST /org/manage.json?id={org_id} - update org details. expects the same JSON model that GET returned
	GET /org/members.json?id={org_id} - returns list of members organized by brand {brand id is array key}
**/

import api from "../common/utils/axiosApi"
import { APIError, Organization, OrgMember } from "../types"

const ENDPOINT = "/org/manage.json"
const ENDPOINT_MEMBERS = "/org/members.json"

const org = {
  get: async (): Promise<Organization | APIError> => {
    const { data } = await api.get(ENDPOINT)
    return data
  },
  getById: async (org_id: string): Promise<Organization | APIError> => {
    if (!org_id) {
      throw new Error("org id missing")
    }
    const { data } = await api.get(`${ENDPOINT}?id=${org_id}`)
    return data
  },
  update: async (orgData: Organization): Promise<Organization | APIError> => {
    if (!orgData.id) {
      throw new Error("org id missing")
    }

    const { data } = await api.post(ENDPOINT, orgData)
    return data
  },
  getMembers: async (
    org_id: string
  ): Promise<{ count: number; members: OrgMember[] } | APIError> => {
    if (!org_id) {
      throw new Error("org id missing")
    }
    const {
      data: { count, members }
    } = await api.get<{
      count: number
      members: {
        [key: string]: {
          members: OrgMember[]
        }
      }
    }>(`${ENDPOINT_MEMBERS}?id=${org_id}`)

    return {
      count,
      members: Object.values(members).flatMap(a => a.members)
    }
  }
}

export default org
