import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import ButtonGroup from "../button-group"
import { ChartOptions } from "./mock"

interface Stat {
  key: string
  label: string
  value: number | string
}

interface Props {
  title: string
  chartData: any
  stats: Stat[]
}

const FILTER_BUTTONS = [
  {
    key: "90d",
    label: "90 Days",
    disabled: false,
    onClick: () => true
  },
  {
    key: "30d",
    label: "30 Days",
    disabled: false,
    onClick: () => true
  },
  {
    key: "7d",
    label: "7 Days",
    disabled: false,
    onClick: () => true
  }
]

const ChartWithStats: React.FC<Props> = ({ title, chartData, stats }) => {
  return (
    <div className="bg-white flex-1 p-6 rounded-md overflow-hidden">
      <div className="flex items-center justify-between">
        <div className="font-bold text-lg">{title}</div>
        <div>
          <ButtonGroup buttons={FILTER_BUTTONS} active="7d" />
        </div>
      </div>
      <div className="py-6">
        <HighchartsReact highcharts={Highcharts} options={ChartOptions} />
      </div>
      <div className="flex items-center gap-6 px-8">
        {stats.map(({ key, label, value }) => (
          <div key={key}>
            <div className="text-gray-500 uppercase text-sm">{label}</div>
            <div className="font-bold text-xl pt-1">{value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ChartWithStats
