import api from "../common/utils/axiosApi"
import { APIError, UserDetails, UserResponse } from "../types"

const ENDPOINT_LOGIN = "/api/v1/login"
const ENDPOINT_MASQUERADE = (loid: string) => `/api/v1/masquerade?uid=${loid}`
const ENDPOINT_DELETE_MASQUERADE = "/api/v1/masquerade"
const ENDPOINT_GET_ME = "/api/v1/me"

const auth = {
  login: async ({
    username,
    password
  }: {
    username: string
    password: string
  }): Promise<UserResponse> => {
    const { data } = await api.post(ENDPOINT_LOGIN, {
      username,
      password
    })

    return data
  },
  masquerade: async (loid: string): Promise<any> => {
    const { data } = await api.get(ENDPOINT_MASQUERADE(loid))
    return data
  },
  deleteMasquerade: async (): Promise<any> => {
    const { data } = await api.delete(ENDPOINT_DELETE_MASQUERADE)
    return data
  },
  getMe: async (): Promise<UserDetails | APIError> => {
    const { data } = await api.get(ENDPOINT_GET_ME)
    return data
  }
}

export default auth
