import { FC } from "react"
import { DateTime } from "luxon"
import { Lead } from "../../../../types"

interface Props {
  data: Lead
}

const OwnerCell: FC<Props> = ({ data }) => {
  const { owner, date_time } = data

  return (
    <td className="px-4">
      <div>
        <div className="">
          {DateTime.fromISO(date_time).toFormat("LLL dd yyyy 'at' HH:mm a")}
        </div>
        <div className="">by {owner}</div>
      </div>
    </td>
  )
}

export default OwnerCell
