import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ToastType } from "../../types"
import { RootState } from "../store"

type IntState = {
  toasts: ToastType[]
}

const initialState: IntState = {
  toasts: []
}

const notificationsSlice = createSlice({
  name: "toasts",
  initialState,
  reducers: {
    pushToast: (state, action: PayloadAction<ToastType>) => {
      const toasts = state.toasts.concat(action.payload)
      return { toasts }
    },
    deleteToast: (state, action) => {
      const toasts = state.toasts
      return {
        toasts: [
          ...toasts.slice(0, action.payload),
          ...toasts.slice(action.payload + 1)
        ]
      }
    }
  }
})

const { reducer, actions } = notificationsSlice

export const { pushToast, deleteToast } = actions

export const selectToast = (state: RootState) => state.notifications.toasts

export default reducer
