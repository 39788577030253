import { ChevronRight } from "lucide-react"
import { setPreviewModal } from "../../../../../redux/slices/integrations"
import { Integration, IntegrationStatusValues } from "../../../../../types"
import { useAppDispatch, useAppSelector } from "../../../../hooks/useStore"
import If from "../../../if"
import Status from "../../../status"

type Props = {
  info: any
}

const IntegrationCell = ({ info }: Props) => {
  const dispatch = useAppDispatch()
  const status: IntegrationStatusValues | undefined = useAppSelector(
    state => state.integrations?.integrationsStatus?.[info?.row.original.id]
  )
  const { title, type, app_key }: Integration = info?.row.original

  const StatusTag =
    status?.status === "active"
      ? Status.Active
      : status?.status === "idle"
      ? Status.Idle
      : Status.Initial

  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3">
      <div className="flex">
        <div className="pl-4">
          <strong className="text-base flex items-center">
            {title}{" "}
            <If
              condition={typeof status !== "undefined"}
              then={<StatusTag className="ml-2" />}
            />
          </strong>
          <span className="text-sm text-neutral-400">{type}</span>
        </div>
        <button
          onClick={() => {
            dispatch(
              setPreviewModal({
                isOpen: true,
                app_key,
                title
              })
            )
          }}
          type="button"
          className="group appearance-none cursor-pointer ml-auto flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300 rounded"
        >
          <span className="transition-colors text-base border-dashed border-neutral-400 border-b inline-block mr-6 ml-3 group-hover:text-primary-400">
            Desktop/Mobile preview
          </span>
          <ChevronRight className="text-neutral-400" size={24} />
        </button>
      </div>
    </td>
  )
}

export default IntegrationCell
