type Props = {
  children: React.ReactNode
  className?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  refProp?: React.Ref<HTMLButtonElement>
}

const Button = ({
  children,
  className = "",
  type = "button",
  disabled = false,
  onClick,
  refProp
}: Props) => (
  <button
    ref={refProp}
    disabled={disabled}
    type={type}
    className={`${className} transition-colors relative inline-flex justify-center items-center py-3 px-4 border text-sm font-medium rounded-md focus:outline-none`}
    onClick={onClick}
  >
    {children}
  </button>
)

export default Button
