import ChartWithStats from "../../common/ui/chart-with-stats"

const STATS_EMBEDS = [
  {
    key: "total_leads",
    label: "Total Leads",
    value: 12
  },
  {
    key: "total_pageviews",
    label: "Total Page Views",
    value: 1
  },
  {
    key: "conversion_rate",
    label: "Conversion Rate",
    value: "85.7%"
  }
]

const STATS_EMAILS = [
  {
    key: "eamils_sent",
    label: "Emails Sent",
    value: 20
  },
  {
    key: "opened_emails",
    label: "Opened Emails",
    value: 5
  },
  {
    key: "open_rate",
    label: "Open Rate",
    value: "25%"
  }
]

const Charts = () => {
  return (
    <>
      <ChartWithStats
        title="Embeds performance"
        chartData={[]}
        stats={STATS_EMBEDS}
      />
      <ChartWithStats
        title="Subscription email performance"
        chartData={[]}
        stats={STATS_EMAILS}
      />
    </>
  )
}

export default Charts
