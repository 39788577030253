import { Badge } from "../../../../ui"

type Props = {
  info: any
}

const UserCell = ({ info }: Props) => {
  const { uid, brandAdmin, picture, email } = info?.row.original
  return (
    <td className="whitespace-nowrap py-4 pl-2 pr-3">
      <div className="flex">
        <img
          className="h-12 w-12 rounded-full object-cover"
          src={picture}
          alt={`${info.getValue()} thumbnail`}
        />
        <div className="pl-4">
          <strong className="text-base block">
            {info.getValue()}{" "}
            {brandAdmin ? <Badge.Success>Admin</Badge.Success> : ""}
          </strong>
          <span className="text-sm text-neutral-400">ID: {uid}</span>
          <div className="whitespace-nowrap text-sm text-gray-500 md:hidden">
            {email}
          </div>
        </div>
      </div>
    </td>
  )
}

export default UserCell
