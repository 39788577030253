import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"

export interface DropdownItem {
  name: string
  href?: string
  icon: (
    props: React.SVGProps<SVGSVGElement> & { size?: number }
  ) => JSX.Element
  onClick?: (item: DropdownItem) => void
}

interface Props {
  menuBtn: JSX.Element
  items: DropdownItem[]
  handleClick: (item: DropdownItem) => void
}

const Dropdown: React.FC<Props> = ({ menuBtn, items, handleClick }) => {
  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          {menuBtn}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {items.map(item => (
            <Menu.Item key={item.name}>
              <div
                className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                onClick={() => handleClick(item)}
              >
                <item.icon className="mr-2 text-gray-400" size={20} />
                <div>{item.name}</div>
              </div>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
