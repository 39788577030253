import { ChangeEvent, FC } from "react"

interface Props {
  file?: File
  filename: string
  setFile: (file: File) => void
  setFilename: (name: string) => void
}

const ImageUploader: FC<Props> = ({ file, filename, setFile, setFilename }) => {
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      setFile(e.target.files?.[0])
      setFilename(e.target.files?.[0].name ?? "")
    }
  }

  return (
    <div className="ml-5 text-center">
      <label className="cursor-pointer text-sm border border-neutral-300 rounded-md overflow-hidden px-2 py-1 bg-neutral-100">
        Choose File
        <input
          name="file"
          type="file"
          hidden
          accept="image/*"
          onChange={handleChangeInput}
        />
      </label>
    </div>
  )
}

export default ImageUploader
