import { FC } from "react"
import { ChevronRightIcon } from "@heroicons/react/solid"
import { Link } from "react-router-dom"

interface Props {
  pages: {
    name: string
    href: string
    current?: boolean
  }[]
}

const BreadCrumbs: FC<Props> = ({ pages }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, idx) => (
          <li key={page.name}>
            <div className="flex items-center">
              {!!idx && (
                <ChevronRightIcon
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              )}
              <Link
                className={`ml-${
                  idx > 0 ? "4" : "0"
                } text-sm font-medium text-gray-500 hover:text-gray-700`}
                to={page.href}
              >
                {page.name}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default BreadCrumbs
