import { Outlet, useLocation } from "react-router-dom"
import { Logo, Card } from "../../ui"

type Props = {
  title?: string
}

const SingleForm: React.FC<Props> = ({ title }) => {
  const { pathname } = useLocation()

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8 single-form bg-primary-alt-400 bg-bb-logos">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="mx-auto h-12 w-auto" isFull={false} />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
          {pathname === "/user/password"
            ? "Forgot your password?"
            : "Welcome back"}
        </h2>

        <p className="mt-2 text-center text-sm text-white">
          {pathname === "/user/password"
            ? "Enter your e-mail and we'll help you reset your password."
            : "Sign in to continue to your BankingBridge account."}
        </p>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <Card>
          <Outlet />
        </Card>
      </div>
    </div>
  )
}

export default SingleForm
