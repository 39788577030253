import { Popover } from "@headlessui/react"
import { ChevronDown, ChevronUp } from "lucide-react"

export type SortDirection = "asc" | "desc"

function SimpleSorter({
  sortDir,
  changeSort,
  className = ""
}: {
  sortDir: SortDirection
  className?: string
  changeSort: (newDir: SortDirection) => void
}) {
  return (
    <div className={className}>
      <Popover as="div" className="relative z-10 inline-block text-left">
        <Popover.Button className="group inline-flex items-center justify-center appearance-none px-3 py-2 border border-neutral-200 rounded-md placeholder-gray-400 focus:outline-none hover:bg-primary-50 hover:border-primary-500 hover:text-primary-600 sm:text-sm">
          <span className="text-gray-400 mr-2">Sort by:</span>
          {sortDir === "asc" ? (
            <>
              A to Z <ChevronDown size={16} aria-hidden="true" />
            </>
          ) : (
            <>
              Z to A <ChevronUp size={16} aria-hidden="true" />
            </>
          )}
        </Popover.Button>

        <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="space-y-4">
            <div className="flex items-center">
              <input
                id="sort-asc"
                name="sort-asc"
                checked={sortDir === "asc"}
                onChange={() => changeSort("asc")}
                type="checkbox"
                className="h-4 w-4 border-gray-300 rounded text-indigo-500 focus:ring-indigo-400"
              />
              <label
                htmlFor="sort-asc"
                className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
              >
                A to Z
              </label>
            </div>
            <div className="flex items-center">
              <input
                id="sort-desc"
                name="sort-desc"
                checked={sortDir === "desc"}
                onChange={() => changeSort("desc")}
                type="checkbox"
                className="h-4 w-4 border-gray-300 rounded text-indigo-500 focus:ring-indigo-400"
              />
              <label
                htmlFor="sort-desc"
                className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
              >
                Z to A
              </label>
            </div>
          </div>
        </Popover.Panel>
      </Popover>
    </div>
  )
}

export default SimpleSorter
