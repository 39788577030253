const BrandCell = ({ info }: any) => {
  const { bank, logo } = info?.row.original
  return (
    <td className="whitespace-nowrap py-4 px-3">
      <div className="flex items-center">
        <img className="h-max max-h-7" src={logo} alt={`${bank} logo`} />
        <div className="pl-4 text-sm hidden lg:block">{bank}</div>
      </div>
    </td>
  )
}

export default BrandCell
