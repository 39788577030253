import { Navigate, useLocation } from "react-router-dom"
import { selectUser } from "../../redux/slices/auth"
import { UserRole } from "../../types"
import { useAppSelector } from "../hooks/useStore"

interface Props {
  allowedRoles: UserRole[]
  children: JSX.Element
}

const RequireRole: React.FC<Props> = ({ allowedRoles, children }) => {
  const location = useLocation()
  const isDashboard = location.pathname === "/"

  const auth = useAppSelector(selectUser)

  if (!auth?.user) return null

  const hasRole = auth.user.roles.some(r => allowedRoles.includes(r))

  return hasRole ? (
    children
  ) : isDashboard ? null : (
    <Navigate to="/" state={{ from: location }} replace />
  )
}

export default RequireRole
