import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import bbApi from "../../api"
import { Brand, OrgMember } from "../../types"
import { BrandReviews, CreateBrandPayload } from "../../types/Brand"
import { RootState } from "../store"
import { setApiError, setMessage } from "./message"

export interface BrandState {
  brands: Brand[]
  members: OrgMember[]
  isLoading: boolean
  isAdding: boolean
  isUpdating: boolean
  reviews: {
    [key: string]: BrandReviews
  }
  error: any
}

const initialState: BrandState = {
  brands: [],
  reviews: {},
  members: [],
  isLoading: false,
  isAdding: false,
  isUpdating: false,
  error: null
}

export const fetchBrands = createAsyncThunk(
  "brand/fetchBrands",
  async (_, thunkAPI) => {
    try {
      const data = await bbApi.brand.get()

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const fetchBrand = createAsyncThunk(
  "brand/fetchBrand",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const data = await bbApi.brand.getById(id)

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const fetchBrandReviews = createAsyncThunk(
  "brand/fetchBrandReviews",
  async (params: any, thunkAPI) => {
    try {
      const data = await bbApi.brand.reviews(params)

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { id: params.id, reviews: data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const addBrand = createAsyncThunk(
  "brand/addBrand",
  async (payload: CreateBrandPayload, thunkAPI) => {
    try {
      const data = await bbApi.brand.add(payload)

      if (data.status === "error") {
        thunkAPI.dispatch(
          setApiError({
            status: "error",
            message: data.message ?? "something went wrong",
            password: ""
          })
        )
        return thunkAPI.rejectWithValue(data)
      } else if (data.brand_id) {
        thunkAPI.dispatch(fetchBrand({ id: data.brand_id }))
      }

      return { brand_id: data.brand_id }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateBrand = createAsyncThunk(
  "brand/updateBrand",
  async (payload: Brand, thunkAPI) => {
    try {
      const data = await bbApi.brand.update(payload)

      if (data.status === "error") {
        thunkAPI.dispatch(
          setApiError({
            status: "error",
            message: data.message ?? "something went wrong",
            password: ""
          })
        )
        return thunkAPI.rejectWithValue(data)
      } else if (data.status === "success") {
        thunkAPI.dispatch(fetchBrand({ id: payload.id }))
      }

      return data
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteBrand = createAsyncThunk(
  "brand/deleteBrand",
  async (payload: { id: string }, thunkAPI) => {
    try {
      const data = await bbApi.brand.delete(payload.id)

      if (data.status === "error") {
        thunkAPI.dispatch(
          setApiError({
            status: "error",
            message: data.message ?? "something went wrong",
            password: ""
          })
        )
        return thunkAPI.rejectWithValue(data)
      }

      return data
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const fetchBrandMembers = createAsyncThunk(
  "brand/fetchBrandMembers",
  async (brandId: string, thunkAPI) => {
    try {
      const data = await bbApi.brand.getMembers(brandId)

      if ("status" in data) {
        if (data.status === "Error") {
          thunkAPI.dispatch(setApiError(data))
          return thunkAPI.rejectWithValue(data)
        }
        return
      }

      return { data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    setBrands: (state, action: PayloadAction<{ data: Brand[] }>) => {
      state.brands = action.payload.data
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchBrands.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      state.isLoading = false
      state.brands = action.payload?.data ?? []
    })
    builder.addCase(fetchBrands.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(fetchBrand.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchBrand.fulfilled, (state, action) => {
      state.isLoading = false
      const { data } = action.payload ?? {}
      if (!data) return
      if (state.brands.find(brand => brand.id === data.id)) {
        state.brands = state.brands.map(brand =>
          brand.id === data.id ? data : brand
        )
      } else {
        state.brands = [...state.brands, data]
      }
    })
    builder.addCase(fetchBrand.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(fetchBrandMembers.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchBrandMembers.fulfilled, (state, action) => {
      state.isLoading = false
      const data = action.payload?.data ?? []
      const currentMembers = state.members.filter(
        m => !data.find(item => item.uid === m.uid)
      )

      state.members = [...currentMembers, ...data]
    })
    builder.addCase(addBrand.pending, (state, action) => {
      state.isAdding = true
    })
    builder.addCase(addBrand.fulfilled, (state, action) => {
      state.isAdding = false
    })
    builder.addCase(addBrand.rejected, (state, action) => {
      state.isAdding = false
      state.error = action.payload
    })
    builder.addCase(updateBrand.pending, (state, action) => {
      state.isAdding = true
    })
    builder.addCase(updateBrand.fulfilled, (state, action) => {
      state.isAdding = false
    })
    builder.addCase(updateBrand.rejected, (state, action) => {
      state.isAdding = false
      state.error = action.payload
    })
    builder.addCase(deleteBrand.pending, (state, action) => {
      state.isAdding = true
    })
    builder.addCase(deleteBrand.fulfilled, (state, action) => {
      state.isAdding = false
    })
    builder.addCase(deleteBrand.rejected, (state, action) => {
      state.isAdding = false
      state.error = action.payload
    })
    builder.addCase(fetchBrandReviews.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchBrandReviews.fulfilled, (state, action) => {
      state.isLoading = false
      if (action.payload) {
        state.reviews = {
          ...state.reviews,
          [action.payload.id]: action.payload.reviews
        }
      }
    })
    builder.addCase(fetchBrandReviews.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  }
})

export const { setBrands } = brandSlice.actions

export const selectBrands = (state: RootState) => state.brand.brands
export const selectBrandMembers = (state: RootState) => state.brand.members
export const selectBrandReviews = (state: RootState) => state.brand.reviews
export const selectBrandIsLoading = (state: RootState) => state.brand.isLoading
export const selectBrandIsAdding = (state: RootState) => state.brand.isAdding
export const selectBrandIsUpdating = (state: RootState) =>
  state.brand.isUpdating

export default brandSlice.reducer
