import { FC } from "react"
import { OrgMember } from "../../../../types"
import { LOsTable } from "../../loan-officers"

interface Props {
  isLoading: boolean
  data: OrgMember[]
}

const BrandDetailsLoanOfficers: FC<Props> = ({ isLoading, data }) => {
  return (
    <div className="p-6">
      <LOsTable isLoading={isLoading} data={data} />
    </div>
  )
}

export default BrandDetailsLoanOfficers
