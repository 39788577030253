import { useEffect, useState } from "react"
import { selectUserDetails } from "../../../../redux/slices/auth"
import { selectIntegrations } from "../../../../redux/slices/integrations"
import { Integration, UserDetails } from "../../../../types"
import { useAppSelector } from "../../../hooks/useStore"
import { OtherTable } from "../../../ui"
import columns from "./columns"
import Card from "./card"

const IntTable = () => {
  const user: UserDetails | any = useAppSelector(selectUserDetails)
  const integrations: Integration[] = useAppSelector(selectIntegrations)
  const [tabs, setTabs] = useState<{ label: string; ids: string[] }[]>([])
  const [data, setData] = useState<{}[]>([])
  const [currentTab, setCurrentTab] = useState<string[]>([user.id])

  useEffect(() => {
    // Get integrations from others users of the same org
    let owners: string[] = []
    integrations.forEach((integration: Integration) => {
      if (![user.id].includes(integration.owner)) {
        owners.push(integration.owner)
      }
    })

    const newTabs = [
      {
        label: "My integrations",
        ids: [user.id]
      },
      {
        label: "Brand integrations",
        ids: owners
      }
    ]

    setTabs(newTabs)
  }, [integrations, currentTab, user.id])

  useEffect(() => {
    // Get integrations that are on currentTab array and push then to an array
    let newData: Integration[] = []
    integrations.forEach((integration: Integration) => {
      if (currentTab.includes(integration.owner)) {
        newData.push(integration)
      }
    })
    setData(newData)
  }, [currentTab, integrations])

  return (
    <OtherTable
      CardComponent={Card}
      noHeader
      noToolbar
      tabsData={{ currentTab, tabs, onChangeTab: setCurrentTab }}
      data={data}
      columns={columns}
    />
  )
}

export default IntTable
