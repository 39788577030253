import { DateTime } from "luxon"

type Props = {
  timestamp: string
}

const TimestampCell = ({ timestamp }: Props) => (
  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 hidden md:table-cell">
    {DateTime.fromMillis(parseInt(timestamp, 10) * 1000).toFormat(
      "dd/LL/yyyy 'at' HH:mm:ss"
    )}
  </td>
)

export default TimestampCell
