type Props = {
  children: React.ReactNode
  className?: string
}

const Card = ({ children, className = "" }: Props) => (
  <div
    className={`${className} bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10`}
  >
    {children}
  </div>
)

export default Card
