import Form from "../../../form"

type Props = {
  globalFilter: string
  searchPlaceholder: string
  onSearch: (value: string | number) => void
  children?: React.ReactNode
  className?: string
}

const classNameDefault = ""

const SearchBar = ({
  globalFilter,
  onSearch,
  searchPlaceholder,
  children,
  className = classNameDefault
}: Props) => (
  <div className={`${className} lg:flex align-middle bg-white`}>
    <Form.SearchInput
      wrapperClassName="w-full lg:w-auto mb-3 lg:mb-0"
      placeholder={searchPlaceholder}
      value={globalFilter ?? ""}
      onSearch={onSearch}
      className="pl-10 lg:min-w-[300px]"
    />
    <div className="flex align-middle w-full">{children}</div>
  </div>
)

export default SearchBar
