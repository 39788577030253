import { FC, useEffect, useState } from "react"
import { Loader2 } from "lucide-react"
import { ColumnDef } from "@tanstack/react-table"
import { useAppDispatch } from "../../../hooks/useStore"
import { OrgMember } from "../../../../types"
import { If, Table } from "../../../ui"
import { masquerade } from "../../../../redux/slices/auth"
import { BrandCell, EmailCell, MenuCell, UserCell } from "./cells"
import LOCard from "./card"
import { ViewType } from "../../table"
import { ToggleView } from "../../table/toolbar"

interface Props {
  isLoading: boolean
  data: OrgMember[]
}

const LOsTable: FC<Props> = ({ isLoading, data }) => {
  const dispatch = useAppDispatch()

  const [viewType, setViewType] = useState<ViewType>("table")

  const onMasquerade = (loid: string) => {
    dispatch(masquerade(loid))
  }

  const onEdit = (loid: string) => true
  const onDelete = (loid: string) => true

  const columns: ColumnDef<OrgMember>[] = [
    {
      id: "name",
      header: "Name",
      accessorFn: row => row.name,
      footer: props => props.column.id,
      cell: props => <UserCell info={props} />
    },
    {
      id: "bank",
      header: "Brand",
      accessorFn: row => row.bank,
      footer: props => props.column.id,
      cell: props => <BrandCell info={props} />
    },
    {
      id: "email",
      header: "Email",
      accessorFn: row => row.email,
      footer: props => props.column.id,
      cell: info => <EmailCell email={info.getValue() as string} />,
      meta: { headerClassName: "hidden md:table-cell" }
    },
    {
      id: "edit",
      header: "Edit",
      footer: props => props.column.id,
      cell: props => (
        <MenuCell
          rowId={props.row.original?.uid}
          onMasquerade={onMasquerade}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )
    }
  ]

  return (
    <If
      condition={isLoading}
      then={
        <div className="min-h-screen flex items-center justify-center">
          <Loader2
            className="animate-spin h-24 w-24 text-primary-alt-400"
            aria-hidden="true"
          />
        </div>
      }
      else={
        <Table
          data={data}
          columns={columns}
          viewType={viewType}
          setViewType={setViewType}
          dropdownFilterColumnId="bank"
          searchPlaceholder="Search LO's by name, email or brand…"
          renderCard={row => <LOCard row={row} />}
          extraHeaderElement={
            <ToggleView
              className="ml-auto"
              viewType={viewType}
              setViewType={setViewType}
            />
          }
        />
      }
    />
  )
}

export default LOsTable
