import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Form, Formik, FormikProps } from "formik"
import { Search } from "lucide-react"
import { Brand } from "../../../../types"
import Checkbox from "../../form/checkbox"
import Input from "../../form/input"
import SidePanel from "../../side-panel"
import ImgPallete from "../../../../assets/icons/pallete.png"
import WysiwygEditor from "../../form/WysiwygEditor"
import bbApi from "../../../../api"
import Autocomplete, { Option } from "../../form/autocomplete"
import { AutocompleteOption } from "../../../../types/Brand"
import ImageUploader from "../../image-uploader"
import { useAppDispatch, useAppSelector } from "../../../hooks/useStore"
import {
  addBrand,
  selectBrandIsAdding,
  updateBrand
} from "../../../../redux/slices/brand"
import { selectOrgDetails } from "../../../../redux/slices/org"
import { pushToast } from "../../../../redux/slices/notifications"
import { mapBrandToDraft, mapDraftToBrand } from "../../../utils/brand"

interface Props {
  data?: Brand
  open: boolean
  onClose: () => void
}

const ManageBrand: FC<Props> = ({ data, open, onClose }) => {
  const dispatch = useAppDispatch()
  const [placeQuery, setPlaceQuery] = useState<string>("")
  const [placeOptions, setPlaceOptions] = useState<AutocompleteOption[]>([])
  const [selectedPlace, setSelectedPlace] = useState<Option>()
  const [file, setFile] = useState<File>()
  const [filename, setFilename] = useState<string>("")
  const [loading, setLoading] = useState<boolean>(false)

  const isAdding = useAppSelector(selectBrandIsAdding)
  const orgDetails = useAppSelector(selectOrgDetails)

  const isCreate = !data?.id
  const title = `${isCreate ? "Add new" : "Edit"} brand`
  const subTitle = isCreate
    ? "Setup the details of your new company"
    : undefined
  const submitLabel = isCreate ? "Add brand" : "Update"

  const formRef = useRef<FormikProps<Brand>>(null)

  const autoCompleteOptions = useMemo(
    () =>
      placeOptions.map(({ place_id, description }) => ({
        id: place_id,
        text: description
      })),
    [placeOptions]
  )

  const initialValues: Brand = data
    ? mapBrandToDraft(data)
    : {
        id: "",
        disable_itemized_fees: false,
        disable_receipt_emails: false,
        disable_subscription_emails: false,
        website: "",
        logo: "",
        disclaimer: "",
        static_disclaimer: "",
        nmls: "",
        about: "",
        target_price: "",
        org_id: orgDetails?.id,
        place_id: "",
        zillow_brand_name: "",
        name: "",
        primary_color: "",
        secondary_color: "",
        non_bank: false,
        payment_details_disclaimer: "",
        loan_calc_disclaimer: "",
        lead_capture_disclaimer: "",
        experience_id: ""
      }

  const setFormField = (name: string, val: string) => {
    formRef.current?.setFieldValue(name, val)
  }

  const resetForm = () => {
    setPlaceOptions([])
    setPlaceQuery("")
    setSelectedPlace(undefined)
    setLoading(false)
    setFile(undefined)
    setFilename("")
    formRef.current?.resetForm()
  }

  const uploadLogo = useCallback(async () => {
    try {
      if (file && filename) {
        setLoading(true)
        const data = await bbApi.asset.uploadImage(file, filename)

        if ("status" in data) {
          console.log("File upload error: ", data)
        } else {
          formRef.current?.setFieldValue("logo", data.url)
        }

        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }, [file, filename])

  const onSubmit = async () => {
    if (formRef.current?.values) {
      if (isCreate) {
        const res = await dispatch(
          addBrand(mapDraftToBrand(formRef.current?.values))
        )
        if ((res.payload as any).brand_id) {
          dispatch(
            pushToast({
              msg: "Brand has been added successfully",
              title: "Info",
              timeout: 3000
            })
          )
        }
      } else {
        const res = await dispatch(
          updateBrand(mapDraftToBrand(formRef.current?.values))
        )
        if ((res.payload as any).status === "success") {
          dispatch(
            pushToast({
              msg: "Brand has been updated successfully",
              title: "Info",
              timeout: 3000
            })
          )
        }
      }
      resetForm()
      onClose()
    }
  }

  const placeAutoComplete = async (input: string) => {
    try {
      if (!input) {
        return []
      }
      const data = await bbApi.brand.placeAutoComplete({ input })
      setPlaceOptions(data)
    } catch (error) {
      console.error(error)
    }
  }

  const setCurrentPlace = useCallback(
    async (placeId: string) => {
      try {
        const place = await bbApi.brand.getPlace({
          place_id: placeId
        })

        if ("status" in place) {
          dispatch(
            pushToast({
              msg: "Google place API is unavailble, try again later",
              title: "Info",
              timeout: 3000
            })
          )
        } else {
          setSelectedPlace({
            id: place.place_id,
            text: place.name
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    uploadLogo()
  }, [uploadLogo])

  useEffect(() => {
    placeAutoComplete(placeQuery)
  }, [placeQuery])

  useEffect(() => {
    if (selectedPlace) {
      formRef.current?.setFieldValue("place_id", selectedPlace.id)
    }
  }, [selectedPlace])

  useEffect(() => {
    if (initialValues.place_id) {
      setCurrentPlace(initialValues.place_id)
    }
  }, [initialValues.place_id, setCurrentPlace, open])

  return (
    <SidePanel
      title={title}
      subTitle={subTitle}
      open={open}
      onClose={onClose}
      actions={[
        {
          label: submitLabel,
          type: "primary",
          onClick: onSubmit,
          disabled: loading || isAdding
        }
      ]}
    >
      <div className="py-12 px-2">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          innerRef={formRef}
        >
          {({ values }) => (
            <Form>
              <div className="w-full flex items-center">
                <div className="h-24 w-24 flex flex-col items-center justify-center rounded-full border border-gray-200 overflow-hidden">
                  {values.logo ? (
                    <img
                      src={values.logo}
                      className="w-24 h-24 object-contain"
                      alt={filename}
                    />
                  ) : (
                    <span className="text-center">No file selected</span>
                  )}
                </div>

                <ImageUploader
                  file={file}
                  filename={filename}
                  setFile={setFile}
                  setFilename={setFilename}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Name of brand"
                  name="name"
                  placeholder="Ex. Fivestar home loans"
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Website of brand"
                  name="website"
                  beforeLabel="https://"
                  placeholder="Ex. www.google.com"
                />
              </div>
              <div className="grid gap-y-6 gap-x-4 grid-cols-12 pt-8">
                <div className="col-span-6">
                  <Input
                    label="Primary color"
                    name="primary_color"
                    placeholder="Ex. #000000"
                    afterIcon={
                      values.primary_color ? (
                        <div
                          className="w-[26px] h-full rounded-full overflow-hidden border border-neutral-200"
                          style={{
                            backgroundColor: values.primary_color
                          }}
                        />
                      ) : (
                        <img src={ImgPallete} alt="pallete" />
                      )
                    }
                  />
                </div>
                <div className="col-span-6">
                  <Input
                    label="Secondary color"
                    name="secondary_color"
                    placeholder="Ex. #000000"
                    afterIcon={
                      values.secondary_color ? (
                        <div
                          className="w-[26px] h-full rounded-full overflow-hidden border border-neutral-200"
                          style={{
                            backgroundColor: values.secondary_color
                          }}
                        />
                      ) : (
                        <img src={ImgPallete} alt="pallete" />
                      )
                    }
                  />
                </div>
                <div className="col-span-6">
                  <Input label="NMLS" name="nmls" placeholder="Ex. 12345" />
                </div>
              </div>

              <div className="pt-8">
                <Checkbox
                  name="disable_subscription_emails"
                  label="Disable subscription emails"
                  subLabel="By disabling users will not receive any subscription emails from your brand"
                />
              </div>
              <div className="pt-4">
                <Checkbox
                  name="disable_receipt_emails"
                  label="Disable receipt emails"
                  subLabel="By disabling users will not receive any receipt emails from your brand"
                />
              </div>
              <div className="pt-4">
                <Checkbox
                  name="disable_itemized_fees"
                  label="Disable itemized fees"
                  subLabel="TODO: better description"
                />
              </div>
              <div className="pt-4">
                <Checkbox
                  name="non_bank"
                  label="This is a non bank"
                  subLabel="TODO: better description"
                />
              </div>
              <div className="pt-8">
                <WysiwygEditor
                  name="about"
                  label="About brand"
                  initialValue={data?.about}
                  setFieldValue={val => setFormField("about", val)}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="General disclaimer"
                  name="disclaimer"
                  placeholder="Type disclaimer here..."
                  as="textarea"
                  inputProps={{ rows: 4 }}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Static flyer disclaimer"
                  name="static_disclaimer"
                  placeholder="Type disclaimer here..."
                  as="textarea"
                  inputProps={{ rows: 4 }}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Payment details disclaimer"
                  name="payment_details_disclaimer"
                  placeholder="Type disclaimer here..."
                  as="textarea"
                  inputProps={{ rows: 4 }}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Lead capture disclaimer"
                  name="lead_capture_disclaimer"
                  placeholder="Type disclaimer here..."
                  as="textarea"
                  inputProps={{ rows: 4 }}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Loan calculator disclaimer"
                  name="loan_calc_disclaimer"
                  placeholder="Type disclaimer here..."
                  as="textarea"
                  inputProps={{ rows: 4 }}
                />
              </div>
              <div className="pt-8">
                <Autocomplete
                  label="Connect Google reviews of brand"
                  name="place_id"
                  placeholder="Search google reviews of brand"
                  value={selectedPlace}
                  query={placeQuery}
                  options={autoCompleteOptions}
                  onChange={option => setSelectedPlace(option)}
                  onChangeQuery={setPlaceQuery}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Connect Zillow reviews of brand"
                  name="zillow_brand_name"
                  placeholder="Search zillow reviews of brand"
                  beforeIcon={<Search color="#A3A3A9" height="100%" />}
                />
              </div>
              <div className="pt-8">
                <Input
                  label="Connect experience.com reviews of brand"
                  name="experience_id"
                  placeholder="Search experience.com reviews of brand"
                  beforeIcon={<Search color="#A3A3A9" height="100%" />}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </SidePanel>
  )
}

export default ManageBrand
