import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { PrimaryBtn, Button } from "../buttons"

interface Props {
  title: string
  subTitle?: string
  children: React.ReactNode
  open: boolean
  onClose: () => void
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl"
  actions?: {
    label: string
    type: "primary" | "secondary"
    onClick: () => void
    disabled?: boolean
  }[]
}

const SidePanel: React.FC<Props> = ({
  title,
  subTitle,
  open,
  onClose,
  size = "4xl", // TODO: support dynamic size
  children,
  actions
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  className={`pointer-events-auto w-screen max-w-4xl`}
                >
                  <div className="flex h-full flex-col bg-white py-6 shadow-xl">
                    <div className="px-4 sm:px-6 pb-6 border-b border-gray-300">
                      <div className="flex items-start justify-between">
                        <div>
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {title}
                          </Dialog.Title>
                          {subTitle && (
                            <Dialog.Title className="text-sm font-light text-gray-900">
                              {subTitle}
                            </Dialog.Title>
                          )}
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={onClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 px-4 sm:px-6 overflow-auto">
                        <div className="h-full" aria-hidden="true">
                          {children}
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>

                    {actions?.length && (
                      <div className="flex flex-shrink-0 justify-end px-8 pt-4">
                        {actions.map(({ label, type, onClick, ...rest }) =>
                          type === "primary" ? (
                            <PrimaryBtn key={label} onClick={onClick} {...rest}>
                              {label}
                            </PrimaryBtn>
                          ) : (
                            <Button key={label} onClick={onClick} {...rest}>
                              {label}
                            </Button>
                          )
                        )}
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SidePanel
