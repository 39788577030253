import { useMemo, Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"
import { Table } from "@tanstack/react-table"
import { SlidersHorizontal } from "lucide-react"
import Form from "../../../form"

const classNameDefault = ""

function DropdownFilter({
  columnID,
  instance,
  className = classNameDefault
}: {
  className?: string
  columnID: string
  instance: Table<any>
}) {
  const column = instance.getColumn(columnID)

  const firstValue = instance
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id)

  const columnFilterValue = column.getFilterValue()

  const facetedUniqueValues = column.getFacetedUniqueValues()

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(facetedUniqueValues.keys()).sort(),
    [facetedUniqueValues, firstValue]
  )

  return typeof firstValue === "number" ? (
    <div className={className}>
      <div className="flex space-x-2">
        <Form.DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
        <Form.DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <div className={className}>
      <Popover as="div" className="relative z-10 inline-block text-left">
        <Popover.Button className="group inline-flex items-center justify-center appearance-none px-3 py-2 border border-neutral-200 rounded-md placeholder-gray-400 focus:outline-none hover:bg-primary-50 hover:border-primary-500 hover:text-primary-600 sm:text-sm">
          <SlidersHorizontal
            size={16}
            className="flex-shrink-0 mr-1 text-base-dark group-hover:text-primary-600"
            aria-hidden="true"
          />
          <span>Filters</span>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
            <div className="space-y-4">
              <h3>
                <span className="sr-only">Filter by</span>Brand
              </h3>
              {sortedUniqueValues.map((value, optionIdx) => (
                <div key={value} className="flex items-center">
                  <input
                    id={`filter-${columnID}-${optionIdx}`}
                    name={`${columnID}[]`}
                    defaultValue={value}
                    checked={columnFilterValue === value}
                    onChange={e =>
                      column.setFilterValue(
                        columnFilterValue === value ? "" : e.target.value
                      )
                    }
                    type="checkbox"
                    className="h-4 w-4 border-gray-300 rounded text-indigo-500 focus:ring-indigo-400"
                  />
                  <label
                    htmlFor={`filter-${columnID}-${optionIdx}`}
                    className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                  >
                    {value}
                  </label>
                </div>
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  )
}

export default DropdownFilter
