import { OrgMember } from "../../../types"

interface Props {
  data: OrgMember
}

const DashboardLOCard: React.FC<Props> = ({ data }) => {
  return (
    <div className="py-2 flex items-center jusify-between">
      <img
        src={data.picture}
        alt={data.name}
        className=" object-cover w-10 h-10 rounded-full"
      />

      <div className="flex-1 pl-4">
        <div className="text-lg">{data.name}</div>
        <div className="text-sm text-gray-400">{data.email}</div>
      </div>
      <div className="text-gray-400">1d</div>
    </div>
  )
}

export default DashboardLOCard
