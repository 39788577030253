import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import bbApi from "../../api"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { useAppDispatch, useAppSelector } from "../../common/hooks/useStore"
import BrandActivities from "../../common/ui/brand-details/activities"
import BrandDetails from "../../common/ui/brand-details/details"
import BrandDetailsHeader from "../../common/ui/brand-details/header"
import BrandDetailsIntegrations from "../../common/ui/brand-details/integrations"
import BrandDetailsLeads from "../../common/ui/brand-details/leads"
import BrandDetailsLoanOfficers from "../../common/ui/brand-details/loan-officers/BrandDetailsLoanOfficers"
import BrandDetailsPricingLogs from "../../common/ui/brand-details/pricing-logs"
import ManageBrand from "../../common/ui/brands/manage"
import BBDialog from "../../common/ui/dialog"
import Tabs from "../../common/ui/tabs"
import { RequireRole } from "../../common/validation"
import {
  fetchBrand,
  fetchBrandMembers,
  fetchBrandReviews,
  selectBrandIsLoading,
  selectBrandMembers,
  selectBrandReviews,
  selectBrands
} from "../../redux/slices/brand"
import {
  fetchIntegrations,
  selectIntegrations
} from "../../redux/slices/integrations"
import { fetchLeadsByBrand, selectLeads } from "../../redux/slices/lead"
import { pushToast } from "../../redux/slices/notifications"
import { GooglePlace } from "../../types/Brand"

const BrandDetailsPage = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const brands = useAppSelector(selectBrands)
  const brand = useMemo(() => brands.find(b => b.id === id), [brands, id])
  const reviews = useAppSelector(selectBrandReviews)
  const review = useMemo(() => (id ? reviews[id] : undefined), [id, reviews])
  const isLoadingMembers = useAppSelector(selectBrandIsLoading)
  const allMembers = useAppSelector(selectBrandMembers)
  const members = useMemo(
    () => allMembers.filter(m => m.bank_nid === id),
    [allMembers, id]
  )
  const leadsAll = useAppSelector(selectLeads)
  const leads = useMemo(
    () => leadsAll.filter(({ brand_id }) => brand_id === id),
    [id, leadsAll]
  )
  const integrationsAll = useAppSelector(selectIntegrations)
  const integrations = useMemo(
    () => integrationsAll.filter(({ brand_id }) => brand_id === id),
    [id, integrationsAll]
  )

  const [googlePlace, setGooglePlace] = useState<GooglePlace>()

  const [manageBrandVisible, setManageBrandVisible] = useState<boolean>(false)
  const [deleteBrandVisible, setDeleteBrandVisible] = useState<boolean>(false)

  const closeManageBrandSidebar = () => setManageBrandVisible(false)
  const openDeleteBrand = () => setDeleteBrandVisible(true)
  const closeDeleteBrand = () => setDeleteBrandVisible(false)

  const [tab, setTab] = useState<string>("brand-details")

  const onChangeTab = (id: string) => setTab(id)

  const handleEdit = () => {
    setManageBrandVisible(true)
  }

  const handleDelete = () => {}

  const setCurrentPlace = useCallback(
    async (placeId: string) => {
      try {
        const place = await bbApi.brand.getPlace({
          place_id: placeId
        })

        if ("status" in place) {
          dispatch(
            pushToast({
              msg: "Google place API is unavailble, try again later",
              title: "Info",
              timeout: 3000
            })
          )
        } else {
          setGooglePlace(place)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    if (id) {
      dispatch(fetchBrand({ id }))
      dispatch(fetchBrandMembers(id))
      dispatch(fetchLeadsByBrand(id))
      dispatch(fetchIntegrations(id))
    }
  }, [dispatch, id])

  useEffect(() => {
    if (brand?.place_id || brand?.zillow_brand_name || brand?.experience_id) {
      dispatch(
        fetchBrandReviews({
          id: brand.id,
          place_id: brand.place_id,
          zillow_brand_name: brand.zillow_brand_name,
          experience_id: brand.experience_id,
          nmls: brand.nmls
        })
      )
    }
  }, [
    brand?.id,
    brand?.experience_id,
    brand?.nmls,
    brand?.place_id,
    brand?.zillow_brand_name,
    dispatch
  ])

  useEffect(() => {
    if (brand?.place_id) {
      setCurrentPlace(brand.place_id)
    }
  }, [brand?.place_id, setCurrentPlace])

  if (!brand || !id) return <div className="p-6">Brand not found</div>

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.brands}>
      <div className="h-full bg-white">
        <BrandDetailsHeader
          brand={brand}
          onClickDelete={openDeleteBrand}
          onClickEdit={handleEdit}
        />
        <div>
          <Tabs
            currentTab={tab}
            tabs={[
              { id: "brand-details", label: "Brand Details" },
              { id: "activity", label: "Activity" },
              { id: "loan-officers", label: "Loan Officers" },
              { id: "leads", label: "Leads" },
              { id: "integrations", label: "Brand Integrations" },
              { id: "pricing-log", label: "Pricing Log" }
            ]}
            onChange={onChangeTab}
          />
          <div>
            {tab === "brand-details" && (
              <BrandDetails
                brand={brand}
                review={review}
                googlePlace={googlePlace}
              />
            )}
            {tab === "activity" && (
              <BrandActivities brand={brand} members={members} />
            )}
            {tab === "loan-officers" && (
              <BrandDetailsLoanOfficers
                isLoading={isLoadingMembers}
                data={members}
              />
            )}
            {tab === "leads" && <BrandDetailsLeads data={leads} />}
            {tab === "integrations" && (
              <BrandDetailsIntegrations data={integrations} />
            )}
            {tab === "pricing-log" && <BrandDetailsPricingLogs brandId={id} />}
          </div>
        </div>
        <ManageBrand
          open={manageBrandVisible}
          data={brand}
          onClose={closeManageBrandSidebar}
        />
        <BBDialog
          open={deleteBrandVisible}
          onClose={closeDeleteBrand}
          title="Delete brand"
          body="By doing this all the information stored in the system about this brand will be deleted. Are you sure?"
          okLabel="Yes, Delete"
          cancelLabel="Cancel"
          onOk={handleDelete}
          onCancel={closeDeleteBrand}
        />
      </div>
    </RequireRole>
  )
}

export default BrandDetailsPage
