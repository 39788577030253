import { ColumnDef } from "@tanstack/react-table"
import TH from "../../../th"

import { IntegrationCell } from "../cells"

const columns: ColumnDef<any>[] = [
  {
    id: "title",
    header: ({ header }) => <TH header={header} srOnly label="Name" />,
    footer: props => props.column.id,
    cell: info => <IntegrationCell info={info} />
  },
  {
    id: "owner",
    header: ({ header }) => <TH header={header} label="owner" srOnly />,
    footer: props => props.column.id,
    cell: () => <></>
  }
]

export default columns
