type Props = {
  email: string
}

const EmailCell = ({ email }: Props) => (
  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500 hidden md:table-cell">
    {email}
  </td>
)

export default EmailCell
