import { useEffect, useCallback } from "react"
import { Navigate } from "react-router-dom"
import { Loader2 } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../common/hooks/useStore"
import { logout } from "../../redux/slices/auth"

const LogoutPage = () => {
  const { isLoggedIn }: any = useAppSelector(state => {
    return state?.auth ?? false
  })

  const dispatch = useAppDispatch()

  const logOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  useEffect(() => {
    logOut()
  })

  if (!isLoggedIn) {
    return <Navigate to="/user/login" />
  }

  return (
    <div className="grid justify-items-center">
      <Loader2
        className="animate-spin h-10 w-auto text-primary-alt-400"
        aria-hidden="true"
      />
    </div>
  )
}

export default LogoutPage
