export const ChartOptions: Highcharts.Options = {
  chart: {
    type: "column"
  },
  title: {
    align: "left",
    text: ""
  },
  subtitle: {
    align: "left",
    text: ""
  },
  accessibility: {
    announceNewData: {
      enabled: true
    }
  },
  xAxis: {
    type: "category"
  },
  yAxis: {
    title: {
      text: "LEADS"
    }
  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      dataLabels: {
        enabled: true,
        format: "{point.y}"
      }
    }
  },

  tooltip: {
    headerFormat: '<span style="font-size:11px">{series.value}</span><br>',
    pointFormat:
      '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> leads<br/>'
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 600
        },
        chartOptions: {
          legend: {
            enabled: false
          }
        }
      }
    ]
  },

  series: [
    {
      name: "Date",
      colorByPoint: true,
      type: "column",
      data: [
        {
          name: "Mar 1",
          y: 62,
          drilldown: "Mar 1"
        },
        {
          name: "Mar 2",
          y: 10,
          drilldown: "Mar 2"
        },
        {
          name: "Mar 3",
          y: 7,
          drilldown: "Mar 3"
        },
        {
          name: "Mar 4",
          y: 5,
          drilldown: "Mar 4"
        },
        {
          name: "Mar 5",
          y: 40,
          drilldown: "Mar 5"
        },
        {
          name: "Mar 6",
          y: 19,
          drilldown: "Mar 6"
        },
        {
          name: "Mar 7",
          y: 10,
          drilldown: "Mar 7"
        }
      ]
    }
  ]
}
