/**
 * Lead manage API wrapper
 */

import api from "../common/utils/axiosApi"
import { getSessionIdFromLocalStorage } from "../common/utils/localStorage"
import { APIError, Lead } from "../types"

const ENDPOINT = "/lead-manage.json"
const LOG_ENDPOINT = "/api/v1/lead-log"

const lead = {
  get: async (params: any): Promise<Lead[] | APIError> => {
    const { data } = await api.get(ENDPOINT, { params })
    return data
  },
  getLog: async (params: any) => {
    const { data } = await api.get(LOG_ENDPOINT, { params })
    return data
  },
  add: async (leadData: any) => {
    const url = "/lead-manage.json?bb_sid=" + getSessionIdFromLocalStorage()
    if (!leadData.owner) {
      console.log("owner missing")
      throw new Error("owner is missing")
    }
    if (!leadData.email && !leadData.phone) {
      throw new Error("not enough info to add a lead")
    }

    const { data } = await api.post(url, leadData)
    return data
  },
  validate: async (validationId: string) => {
    const url = "/lead-validate.json?bb_sid=" + getSessionIdFromLocalStorage()
    const { data } = await api.post(url, { id: validationId })
    return data
  }
}

export default lead
