import { useEffect, useState } from "react"
import * as Yup from "yup"
import { useLocation, useNavigate } from "react-router-dom"
import { Loader2, Lock } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../common/hooks/useStore"
import { PrimaryBtn, DefaultBtn, Form, Alert } from "../../common/ui"
import { login } from "../../redux/slices/auth"
import { clearMessage } from "../../redux/slices/message"

const Login = () => {
  const [loading, setLoading] = useState(false)
  const { isLoggedIn }: any = useAppSelector(state => {
    return state?.auth ?? false
  })
  const { message }: any = useAppSelector(state => state?.message)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(clearMessage())
  }, [dispatch])

  let navigate = useNavigate()
  let location: any = useLocation()
  let from = location.state?.from?.pathname || "/"

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/", { replace: true })
    }
  })

  const initialValues = {
    username: "",
    password: ""
  }
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!")
  })

  type FormValues = {
    username: string
    password: string
  }

  const handleLogin = ({ username, password }: FormValues) => {
    setLoading(true)
    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true })
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Form.Wrapper
        className="space-y-6"
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleLogin}
      >
        <Form.Input label="Username" name="username" autoComplete="username" />
        <Form.Input
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
        />

        <div className="flex items-center justify-between">
          <Form.Checkbox label="Remember me" name="remember-me" />

          <div className="text-sm">
            <a
              href="/user/password"
              className="font-medium text-primary-600 hover:text-primary-500"
            >
              Forgot your password?
            </a>
          </div>
        </div>

        <div>
          <PrimaryBtn
            className={`${loading ? "pointer-events-none" : ""} w-full`}
            type="submit"
            disabled={loading}
            onClick={() => {}}
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              {loading ? (
                <Loader2
                  className="animate-spin h-5 w-5 text-primary-alt-400"
                  aria-hidden="true"
                />
              ) : (
                <Lock className="h-5 w-5 text-primary-600" aria-hidden="true" />
              )}
            </span>
            Sign in
          </PrimaryBtn>
        </div>
      </Form.Wrapper>

      <div className="mt-6">
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-6">
          <DefaultBtn className="w-full" onClick={() => {}}>
            <span className="sr-only">Sign in with Google</span>

            <svg viewBox="0 0 24 24" className="w-5 h-5" aria-hidden="true">
              <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                <path
                  fill="#4285F4"
                  d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                />
                <path
                  fill="#34A853"
                  d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                />
                <path
                  fill="#FBBC05"
                  d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                />
                <path
                  fill="#EA4335"
                  d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                />
              </g>
            </svg>
          </DefaultBtn>
        </div>

        {message && <Alert.Danger className="mt-6" message={message} />}
      </div>
    </>
  )
}

export default Login
