import {
  Users,
  LayoutDashboard,
  Landmark,
  Code2,
  DollarSign,
  Monitor,
  AtSign,
  Contact,
  LogOut
} from "lucide-react"
import { UserRole } from "../../types"
import { NavItemType } from "../../types/NavItem"

export type RoutePath =
  | "dashboard"
  | "brands"
  | "integrations"
  | "loan-officers"
  | "loan-programs"
  | "lead-subscriptions"
  | "pricing-logs"

export const ALLOWED_ROLES_BY_ROUTE: { [key in RoutePath]: UserRole[] } = {
  dashboard: ["loan officer", "bank admin", "administrator"],
  brands: ["bank admin", "administrator"],
  integrations: ["loan officer", "bank admin", "administrator"],
  "loan-officers": ["bank admin", "administrator"],
  "loan-programs": ["bank admin", "administrator"],
  "lead-subscriptions": ["bank admin", "administrator"],
  "pricing-logs": ["loan officer", "bank admin", "administrator"]
}

export const NavbarItems: NavItemType[] = [
  {
    key: "dashboard",
    name: "Org Dashboard",
    href: "/",
    icon: LayoutDashboard
  },
  { key: "brands", name: "Brands", href: "/brands", icon: Landmark },
  {
    key: "loan-officers",
    name: "Loan officers",
    href: "/loan-officers",
    icon: Users
  },
  {
    key: "integrations",
    name: "Integrations",
    href: "/integrations",
    icon: Code2
  },
  {
    key: "loan-programs",
    name: "Loan programs",
    href: "/loan-programs",
    icon: DollarSign
  },
  {
    key: "pricing-logs",
    name: "Pricing logs",
    href: "/pricing-logs",
    icon: Monitor
  },
  {
    key: "lead-subscriptions",
    name: "Leads & subscriptions",
    href: "/lead-subscriptions",
    icon: AtSign
  }
]

export const profileDropdownItems = [
  { name: "My account", href: "#", icon: Contact },
  { name: "Logout", href: "/user/logout", icon: LogOut }
]

export const addNewDropdownItems = [
  { name: "Brand", href: "#", icon: Landmark },
  { name: "Loan officer", href: "#", icon: Users },
  { name: "Integration", href: "#", icon: Code2 },
  { name: "Loan program", href: "#", icon: DollarSign },
  { name: "Lead", href: "#", icon: AtSign }
]
