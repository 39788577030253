/**
  Integrations API wrapper

	JSON endpoint: /integration-manage.json
	GET /api/v1/integrations?brand_id={brand_id} - returns a list of brands integrations
  GET /api/v1/embed-requests?integration_id=<id>&quick_status=true - return the integration status
**/

import api from "../common/utils/axiosApi"
import {
  Integration,
  RootIntegrations,
  IntegrationStatusValues
} from "../types"

const ENDPOINT_INTEGRATIONS = "/api/v1/integrations"
const ENDPOINT_STATUS = "/api/v1/embed-requests"

const integrations = {
  list: async (brandsIDs: string): Promise<RootIntegrations> => {
    const { data } = await api.get<RootIntegrations>(
      `${ENDPOINT_INTEGRATIONS}?brand_id=${brandsIDs}`
    )
    return data
  },
  status: async (id: string): Promise<IntegrationStatusValues> => {
    const { data } = await api.get<IntegrationStatusValues>(
      `${ENDPOINT_STATUS}?integration_id=${id}&quick_status=true`
    )
    return data
  },
  find: async (params: any): Promise<Integration> => {
    const { data } = await api.get(ENDPOINT_INTEGRATIONS, params)
    return data
  }
}

export default integrations
