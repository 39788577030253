import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { setApiError, setMessage } from "./message"
import { RootState } from "../store"
import bbApi from "../../api"
import { setCookie } from "../../common/utils/cookies"
import { User } from "../../types"
import {
  getBBUserFromLocalStorage,
  removeBBUserFromLocalStorage,
  setBBUserInLocalStorage
} from "../../common/utils/localStorage"

const userFromStorage = getBBUserFromLocalStorage()
export const STORE_RESET = "store/reset"

type UserType = {
  username: string
  password: string
}

export interface AuthState {
  isLoggedIn: boolean
  user: User | null
}

export const login = createAsyncThunk(
  "auth/login",
  async ({ username, password }: UserType, thunkAPI) => {
    try {
      const data = await bbApi.auth.login({ username, password })

      if (data.user) {
        setBBUserInLocalStorage(data)
        setCookie(data.session_name, data.sessid, 10)
      }

      if (data?.status === "Error" || data?.status === "error") {
        const msg = "Invalid username or password"
        thunkAPI.dispatch(setMessage(msg))
        return thunkAPI.rejectWithValue(msg)
      }

      return { user: data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const logout = createAsyncThunk("auth/logout", (_, thunkAPI) => {
  thunkAPI.dispatch({
    type: STORE_RESET
  })
  removeBBUserFromLocalStorage()
})

export const masquerade = createAsyncThunk(
  "auth/masquerade",
  async (loid: string, thunkAPI) => {
    try {
      const data = await bbApi.auth.masquerade(loid)

      if (data.user) {
        setBBUserInLocalStorage(data)
        setCookie(data.session_name, data.sessid, 10)
      }

      return { user: data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const deleteMasquerade = createAsyncThunk(
  "auth/deleteMasquerade",
  async (_, thunkAPI) => {
    try {
      const data = await bbApi.auth.deleteMasquerade()

      if (data.user) {
        setBBUserInLocalStorage(data)
        setCookie(data.session_name, data.sessid, 10)
      }

      return { user: data }
    } catch (error: any) {
      const message =
        error?.response?.data?.message || error?.message || error?.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const getMe = createAsyncThunk("auth/getMe", async (_, thunkAPI) => {
  try {
    const data = await bbApi.auth.getMe()

    if ("status" in data) {
      if (data.status === "Error") {
        thunkAPI.dispatch(setApiError(data))
        return thunkAPI.rejectWithValue(data)
      }
      return
    }

    return { userDetails: data }
  } catch (error: any) {
    const message =
      error?.response?.data?.message || error?.message || error?.toString()
    thunkAPI.dispatch(setMessage(message))
    return thunkAPI.rejectWithValue(message)
  }
})

const initialState: AuthState = userFromStorage
  ? { isLoggedIn: true, user: userFromStorage }
  : { isLoggedIn: false, user: null }

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: builder => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload.user
    })
    builder.addCase(login.rejected, state => {
      state.isLoggedIn = false
      state.user = null
    })
    builder.addCase(masquerade.fulfilled, (state, action) => {
      state.isLoggedIn = true
      state.user = action.payload.user
    })
    builder.addCase(masquerade.rejected, state => {})
    builder.addCase(deleteMasquerade.fulfilled, (state, action) => {
      state.user = action.payload.user
    })
    builder.addCase(deleteMasquerade.rejected, state => {})
    builder.addCase(getMe.fulfilled, (state, action) => {
      if (state.user && action.payload?.userDetails) {
        state.user = { ...state.user, user: action.payload?.userDetails }
      }
    })
    builder.addCase(getMe.rejected, state => {})
    builder.addCase(logout.fulfilled, state => {
      state.isLoggedIn = false
      state.user = null
    })
  },
  reducers: {}
})

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn
export const selectUser = (state: RootState) => state.auth.user
export const selectUserDetails = (state: RootState) => state.auth.user?.user

export default authSlice.reducer
