import { FC } from "react"
import { Brand } from "../../../../types"
import { BrandReviews, GooglePlace } from "../../../../types/Brand"
import GoogleReviewsList from "./GoogleReviewsList"
import ZillowReviewsList from "./ZillowReviewsList"
import BrandOverview from "./BrandOverview"
import ExperienceReviewsList from "./ExperienceReviewsList"

interface Props {
  brand: Brand
  review?: BrandReviews
  googlePlace?: GooglePlace
}

const BrandDetails: FC<Props> = ({ brand, review, googlePlace }) => {
  return (
    <div className="p-6 max-w-3xl">
      <BrandOverview brand={brand} />
      <GoogleReviewsList place={googlePlace} reviews={review?.google} />
      <ZillowReviewsList
        reviews={review?.zillow}
        zillowBrandName={brand.zillow_brand_name}
      />
      <ExperienceReviewsList reviews={review?.experience} />
    </div>
  )
}

export default BrandDetails
