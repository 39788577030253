import { useEffect, useState } from "react"
import { Eye, Plus } from "lucide-react"
import { useAppDispatch, useAppSelector } from "../../common/hooks/useStore"
import {
  LightBtn,
  PageHeader,
  PrimaryBtn,
  APIKeyModal,
  ApprovedUrlsModal
} from "../../common/ui"
import { IntegrationsTable } from "../../common/ui/integrations"
import {
  fetchIntegrations,
  setPreviewModal
} from "../../redux/slices/integrations"
import { selectOrgBrandsIDs } from "../../redux/slices/org"
import { ALLOWED_ROLES_BY_ROUTE } from "../../common/constants"
import { RequireRole } from "../../common/validation"
import PreviewModal from "../../common/ui/preview-modal"

const IntegrationsPage = () => {
  const dispatch = useAppDispatch()
  const brandsIDs = useAppSelector(selectOrgBrandsIDs)

  const [APIModalOpen, setAPIModalOpen] = useState(false)
  const [ApprovedUrlsModalOpen, setApprovedUrlsModalOpen] = useState(false)

  useEffect(() => {
    // Fetch organization members
    if (brandsIDs.length) {
      dispatch(fetchIntegrations(brandsIDs))
    }
  }, [dispatch, brandsIDs])

  return (
    <RequireRole allowedRoles={ALLOWED_ROLES_BY_ROUTE.integrations}>
      <>
        <div className="p-6 bg-white">
          <PageHeader title="Integrations">
            <LightBtn
              className="ml-3"
              onClick={() => {
                setApprovedUrlsModalOpen(true)
              }}
            >
              Approved URL'S
            </LightBtn>
            <LightBtn
              className="ml-3"
              onClick={() => {
                setAPIModalOpen(true)
              }}
            >
              <Eye size={16} className="mr-1" /> API Key
            </LightBtn>
            <PrimaryBtn className="ml-3" onClick={() => {}}>
              <Plus size={16} className="mr-1" /> New Integration
            </PrimaryBtn>
          </PageHeader>

          <IntegrationsTable />
        </div>
        <APIKeyModal show={APIModalOpen} toogleModal={setAPIModalOpen} />
        <ApprovedUrlsModal
          show={ApprovedUrlsModalOpen}
          toogleModal={setApprovedUrlsModalOpen}
        />
        <PreviewModal
          onClose={() => {
            dispatch(
              setPreviewModal({
                isOpen: false,
                appKey: ""
              })
            )
          }}
        />
      </>
    </RequireRole>
  )
}

export default IntegrationsPage
