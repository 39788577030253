import {
  AsyncThunk,
  AnyAction,
  createAsyncThunk,
  createSlice
} from "@reduxjs/toolkit"
import bbApi from "../../api"
import { APIKeyBasic, Integration, IntegrationsStatus } from "../../types"
import { RootState } from "../store"
import { pushToast } from "./notifications"

export interface IntState {
  integrationsStatus?: IntegrationsStatus
  list: Integration[]
  isLoading: boolean
  isLoadingIntegrations: boolean
  isLoadingAPIKey: boolean
  APIKey?: APIKeyBasic
  previewModal: {
    isOpen: boolean
    app_key: Integration["app_key"]
    title: string
  }
}

const initialState: IntState = {
  list: [],
  isLoading: false,
  isLoadingIntegrations: false,
  isLoadingAPIKey: false,
  previewModal: {
    isOpen: false,
    app_key: "",
    title: ""
  }
}

export const fetchAPIKey = createAsyncThunk(
  "apikey/get",
  async (loid: string) => {
    const data = await bbApi.apiKey.get(loid)
    if (data.api_key) {
      return data
    }
  }
)

export const generateAPIKey = createAsyncThunk(
  "apikey/generate",
  async (loid: string) => {
    const generatedAPIKey = await bbApi.apiKey.generate(loid)

    return generatedAPIKey.result
  }
)

export const deleteAPIKey = createAsyncThunk(
  "apikey/delete",
  async (id: string, thunkAPI) => {
    const deletedAPIKey = await bbApi.apiKey.delete(id)

    if (deletedAPIKey.status === "success") {
      thunkAPI.dispatch(
        pushToast({
          title: "API Key",
          msg: "API Key deleted",
          theme: "success"
        })
      )
    }
    return deletedAPIKey
  }
)

export const fetchIntegrations = createAsyncThunk(
  "integrations/list",
  async (brandsIDs: string, thunkAPI) => {
    const data = await bbApi.integrations.list(brandsIDs)

    const integrations = data.results

    integrations.forEach(integration => {
      thunkAPI.dispatch(fetchIntegrationsStatus(integration.id))
    })

    return { data }
  }
)

export const fetchIntegrationsStatus = createAsyncThunk(
  "integrations/status",
  async (integrationID: string) => {
    const status = await bbApi.integrations.status(integrationID)
    return { [integrationID]: status }
  }
)

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>

type PendingAction = ReturnType<GenericAsyncThunk["pending"]>
type RejectedAction = ReturnType<GenericAsyncThunk["rejected"]>
type FulfilledAction = ReturnType<GenericAsyncThunk["fulfilled"]>

function isPendingAction(action: AnyAction): action is PendingAction {
  return action.type.startsWith("apikey") && action.type.endsWith("pending")
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.startsWith("apikey") && action.type.endsWith("rejected")
}

function isFulfilledAction(action: AnyAction): action is FulfilledAction {
  return action.type.startsWith("apikey") && action.type.endsWith("fulfilled")
}

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setPreviewModal: (state, action) => {
      state.previewModal = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchIntegrations.pending, state => {
        state.isLoadingIntegrations = true
      })
      .addCase(fetchIntegrations.fulfilled, (state, action) => {
        state.isLoadingIntegrations = false
        const newIds = action.payload.data.results.map(({ id }) => id)
        state.list = [
          ...state.list.filter(({ id }) => !newIds.includes(id)),
          ...action.payload.data.results
        ]
      })
      .addCase(fetchIntegrationsStatus.fulfilled, (state, action) => {
        state.integrationsStatus = {
          ...state.integrationsStatus,
          ...action.payload
        }
      })
      .addCase(fetchIntegrations.rejected, (state, action) => {
        state.isLoadingIntegrations = false
      })
      .addMatcher(isFulfilledAction, (state, action) => {
        state.isLoadingAPIKey = false
        if (action.payload) {
          state.APIKey = action.payload as APIKeyBasic
        }
      })
      .addMatcher(isRejectedAction, state => {
        state.isLoadingAPIKey = false
      })
      .addMatcher(isPendingAction, state => {
        state.isLoadingAPIKey = true
      })
  }
})

export const selectintegrationsStatus = (state: RootState) =>
  state.integrations?.integrationsStatus

export const selectIntegrations = (state: RootState) => state.integrations.list
export const selectPreviewModal = (state: RootState) =>
  state.integrations.previewModal
export const selectAPIKey = (state: RootState) => state.integrations.APIKey
export const selectIsLoadingAPIKey = (state: RootState) =>
  state.integrations.isLoadingAPIKey

export const { setPreviewModal } = integrationsSlice.actions

export default integrationsSlice.reducer
