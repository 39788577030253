import { FC, useEffect, useState } from "react"
import { ContentState, convertToRaw, EditorState } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

interface Props {
  name: string
  label?: string
  initialValue?: string
  setFieldValue: (val: string) => void
}

const WysiwygEditor: FC<Props> = ({
  name,
  label,
  initialValue,
  setFieldValue
}) => {
  const prepareDraft = (value: string) => {
    const draft = htmlToDraft(value)
    const contentState = ContentState.createFromBlockArray(draft.contentBlocks)
    const editorState = EditorState.createWithContent(contentState)
    return editorState
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (contentState: EditorState) => {
    setFieldValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    setEditorState(contentState)
  }

  useEffect(() => {
    if (initialValue) {
      setEditorState(prepareDraft(initialValue))
    }
  }, [initialValue])

  return (
    <div>
      {label ? (
        <label
          htmlFor={name}
          className="block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      ) : null}
      <Editor
        editorState={editorState}
        toolbarHidden={false}
        wrapperClassName="mt-1 rounded-md shadow-sm border border-neutral-200 overflow-hidden"
        editorClassName="px-4 placeholder-gray-400 h-40"
        toolbarClassName="px-0"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            // "list",
            "textAlign",
            "colorPicker",
            // "remove",
            "history"
          ],
          inline: { inDropdown: false },
          list: { inDropdown: false },
          textAlign: { inDropdown: false },
          link: { inDropdown: false },
          history: { inDropdown: false }
        }}
      />
    </div>
  )
}

export default WysiwygEditor
